import React, { createRef, useContext, useEffect, useState } from "react";
import ConsultationComponent from "../../components/ConsultationComponent/ConsultationComponent";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img1 from "../../assets/consultationImages/Frame 427319441.svg";
import img2 from "../../assets/consultationImages/Frame 427319440.svg";
import img3 from "../../assets/consultationImages/Frame 427319443.svg";
import img4 from "../../assets/consultationImages/Frame 427319442.svg";
import img5 from "../../assets/consultationImages/Frame 427319444.svg";
import img6 from "../../assets/consultationImages/Frame 427319445.svg";
import img7 from "../../assets/consultationImages/physician.svg";
import img8 from "../../assets/consultationImages/orthopedic.svg";
import img9 from "../../assets/consultationImages/ent consultant.svg";
import img10 from "../../assets/consultationImages/image 135.svg";
import img11 from "../../assets/consultationImages/image 137.svg";
import img12 from "../../assets/consultationImages/image 138.svg";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
const ConsultationContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "10";

  const arr = [
    {
      arrImg: img1,
      head: "Expertise",
      text: "Experienced specialists providing tailored, top-notch medical care.",
    },
    {
      arrImg: img2,
      head: "Convenience",
      text: "Flexible scheduling for minimal disruption to daily routine.",
    },
    {
      arrImg: img3,
      head: "Comfort",
      text: "Consultations in familiar settings promoting open communication and peace.",
    },
    {
      arrImg: img4,
      head: "Personalized approach",
      text: "Understanding unique medical history, concerns, and preferences.",
    },
    {
      arrImg: img5,
      head: "Comprehensive Care",
      text: "Multidisciplinary collaboration for holistic, tailored treatment.",
    },
    {
      arrImg: img6,
      head: "Family-centered care",
      text: "Inclusive care involving families, fostering support, and active involvement in healing.",
    },
  ];

  const serviceCardsData = [
    {
      images: img7,
      head: "Physician",
      text: "General health assessments and treatment plans for various medical concerns, ensuring overall well-being.",
    },
    {
      images: img8,
      head: "Orthopedic",
      text: "Specialized care for bone, joint, and muscle conditions, facilitating optimal mobility and pain relief.",
    },
    {
      images: img9,
      head: "ENT Consultant",
      text: "Diagnosis and treatment of ear, nose, and throat issues, ensuring optimal respiratory health and comfort.",
    },
    {
      images: img7,
      head: "Physician",
      text: "General health assessments and treatment plans for various medical concerns, ensuring overall well-being.",
    },
    {
      images: img8,
      head: "Orthopedic",
      text: "Specialized care for bone, joint, and muscle conditions, facilitating optimal mobility and pain relief.",
    },
    {
      images: img9,
      head: "ENT Consultant",
      text: "Diagnosis and treatment of ear, nose, and throat issues, ensuring optimal respiratory health and comfort.",
    },
  ];

  const serviceCardsData1 = [
    {
      images: img10,
      drName: "Dr. Raghav Kad",
      designation: "Physician",
      education: "MBBS",
      text: "Diagnosis and treatment of ear, nose, and throat issues, ensuring optimal respiratory health and comfort.",
    },
    {
      images: img11,
      drName: "Dr. Raghav Kad",
      designation: "Physician",
      education: "MBBS",
      text: "General health assessments and treatment plans for various medical concerns, ensuring overall well-being.",
    },
    {
      images: img12,
      drName: "Dr. Raghav Kad",
      designation: "Physician",
      education: "MBBS",
      text: "Diagnosis and treatment of ear, nose, and throat issues, ensuring optimal respiratory health and comfort.",
    },
    {
      images: img10,
      drName: "Dr. Raghav Kad",
      designation: "Physician",
      education: "MBBS",
      text: "Diagnosis and treatment of ear, nose, and throat issues, ensuring optimal respiratory health and comfort.",
    },
    {
      images: img11,
      drName: "Dr. Raghav Kad",
      designation: "Physician",
      education: "MBBS",
      text: "General health assessments and treatment plans for various medical concerns, ensuring overall well-being.",
    },
    {
      images: img12,
      drName: "Dr. Raghav Kad",
      designation: "Physician",
      education: "MBBS",
      text: "Diagnosis and treatment of ear, nose, and throat issues, ensuring optimal respiratory health and comfort.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);

  const setting = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    // centerMode: true,
    // centerPadding: "0",
    // beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting1 = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const dropDownArr = [
    {
      label: "Why should I opt for online consultation?",
      text: "Opt for online consultation for efficient, private, time-saving, and affordable personalised solutions with highly-qualified doctors, within 15 minutes, ensuring your health needs are met conveniently.",
    },
    {
      label: "Will I get the refund if I cancel the online consultation?",
      text: "We understand plans can change. For cancelled consultations, refunds are available if cancelled 48 hours before (full refund), 24 to 48 hours (half refund), and no refund if less than 24 hours.",
    },
    {
      label: "I missed the consultation, what should I do next?",
      text: "If you miss your consultation, don't worry. Call us at 8010091010 for the next available slots. Choose, pay, and confirm your booking hassle-free.",
    },
    {
      label: "How do I get an appointment for video consultation?",
      text: "To schedule a video consultation, simply call 8010091010. Our team will assist you in finding available slots, choosing the right one, and confirming your booking promptly.",
    },
    {
      label: "What kind of consultations can I take through HHY?",
      text: "HHY offers a range of consultations including general physicians, cardiologists, nutritionists, counsellors, and other specialists. We're here to ensure you receive personalised care for your unique health concerns.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <ConsultationComponent
        isMobile={selectIsMobile(state)}
        arr={arr}
        serviceCardsData={serviceCardsData}
        currentSlide={currentSlide}
        serviceCardsData1={serviceCardsData1}
        setting={setting}
        setting1={setting1}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default ConsultationContainer;
