import React, { createRef, useEffect, useState } from "react";
import img2 from "../../assets/physiotherapyImages/physiotherapy bg.jpg";
import "./PhysiotherapyServicePage.css";
import whyChooseUsImg from "../../assets/physiotherapyImages/how instant services woek.svg";
import bg1 from "../../assets/physiotherapyImages/physioBg.svg";
import dadi from "../../assets/homePageImages/Vector.svg";
import whiteImg from "../../assets/physiotherapyImages/Rectangle 2586.svg";
import whiteImg1 from "../../assets/physiotherapyImages/mobile/Rectangle 2586.svg";
import mobileImgMain from "../../assets/physiotherapyImages/Frame 427319569.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bg from "../../assets/physiotherapyImages/bg.png";
const PhysiotherapyServicePage = (props) => {
  const { isMobile, arr, arr1, arr2, handleEnquireNow } = props;
  const mySlider = createRef();

  const setting = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const abcd = arr2.map((item, index) => {
    return (
      <div className="physioPageContainer4-card">
        <img src={item.arrImg} className="" />
        <h5 className="physioPageContainer4-cardHead">{item.head}</h5>
        <p className="physioPageContainer4-cardText">{item.text}</p>
      </div>
    );
  });

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Physiotherapy At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Physiotherapy employs specialised exercises and <br />{" "}
              cutting-edge portable modalities to enhance <br /> mobility,
              alleviate pain, and promote recovery.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Physiotherapy At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Physiotherapy employs specialised exercises and cutting-edge
              portable modalities to <br /> enhance mobility, alleviate pain,
              and promote recovery from injuries or disabilities.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
        {!isMobile && (
          <img
            style={{ top: "70%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      <img
        src={whyChooseUsImg}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2rem auto" }
            : { width: "70%", display: "block", margin: " 3rem auto" }
        }
      />

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Types of Physiotherapy</h4>
        {!isMobile && (
          <img className="homePageContainer4-headMain-dadi4" src={dadi} />
        )}
      </div>

      {isMobile ? (
        // <div>
        //   <Slider className="abcbccb" ref={mySlider} {...setting}>
        //     {abcd}
        //   </Slider>
        // </div>
        <></>
      ) : (
        <div className="physioPageContainer4">
          {isMobile ? (
            <img
              alt="images"
              src={bg}
              className="elderlyCarePageContainer3-img1"
            />
          ) : (
            <img
              alt="images"
              src={bg1}
              className="elderlyCarePageContainer3-img1"
            />
          )}
          {arr1.map((item, index) => {
            return (
              <div className="physioPageContainer4-card">
                <img src={item.arrImg} className="" />
                <h5 className="physioPageContainer4-cardHead">{item.head}</h5>
                <p className="physioPageContainer4-cardText">{item.text}</p>
              </div>
            );
          })}
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Why Physiotherapy?</h4>
        {!isMobile && (
          <img className="homePageContainer4-headMain-dadi5" src={dadi} />
        )}
      </div>
      <div className="careGiverPageContainer3">
        {isMobile ? (
          <img
            alt="images"
            src={bg}
            className="elderlyCarePageContainer3-img1"
          />
        ) : (
          <img
            alt="images"
            src={bg1}
            className="elderlyCarePageContainer3-img1"
          />
        )}
        {arr.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img src={item.arrImg} className="physioPageContainer3-cardImg" />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={whiteImg}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}

                <h5 className="careGiverPageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="physioPageContainer3-cardText2">{item.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PhysiotherapyServicePage;
