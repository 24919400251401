import React, { useContext, useState, useEffect } from "react";
import ElderlyCareServicePage from "../../components/ElderlyCareServicePage/ElderlyCareServicePage";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img1 from "../../assets/elderlyCareImages/Frame 427319402.svg";
import img2 from "../../assets/elderlyCareImages/SVGRepo_iconCarrier.svg";
import img3 from "../../assets/elderlyCareImages/Layer.svg";
import img4 from "../../assets/elderlyCareImages/Frame.svg";
import img5 from "../../assets/elderlyCareImages/Frame (1).svg";
import img6 from "../../assets/elderlyCareImages/Frame (2).svg";
import img7 from "../../assets/elderlyCareImages/Capa_1.svg";
import img8 from "../../assets/elderlyCareImages/Frame 427319400.svg";
import img9 from "../../assets/elderlyCareImages/Frame 427319401.svg";
import img10 from "../../assets/elderlyCareImages/specialised/Layer_1.svg";
import img11 from "../../assets/elderlyCareImages/specialised/Layer_1 (1).svg";
import img12 from "../../assets/elderlyCareImages/specialised/Layer_1 (2).svg";
import img13 from "../../assets/elderlyCareImages/specialised/Layer_1 (3).svg";
import img14 from "../../assets/elderlyCareImages/specialised/Layer_1 (4).svg";
import img15 from "../../assets/elderlyCareImages/specialised/Layer_1 (5).svg";
import img16 from "../../assets/elderlyCareImages/specialised/Layer_1 (6).svg";
import img17 from "../../assets/elderlyCareImages/specialised/Layer_1 (7).svg";
import img18 from "../../assets/elderlyCareImages/specialised/Layer.svg";

import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
const ElderlyCareServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "1";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      head: "Consultations",
      text: "Meetings with healthcare professionals for health assessments and guidance",
    },
    {
      arrImg: img2,
      head: "Health Manager",
      text: "Health coordinator overseeing personalized health plans and patient history.",
    },

    {
      arrImg: img3,
      head: "Trained Caregivers",
      text: "Skilled individuals providing assistance and support.",
    },
    {
      arrImg: img4,
      head: "Health Profiling",
      text: "Comprehensive analysis of health status of the patient.",
    },
    {
      arrImg: img5,
      head: "Dental, Eye, derma",
      text: "Basic medical services for oral, visual, and skin health.",
    },
    {
      arrImg: img6,
      head: "Monitoring Services",
      text: "Continuous tracking of health parameters including vitals.",
    },
    {
      arrImg: img7,
      head: "Emergency Services",
      text: "Immediate medical assistance including ambulance services.",
    },

    {
      arrImg: img8,
      head: "Physiotherapy",
      text: " Rehabilitation exercises for physical health improvement",
    },
    {
      arrImg: img9,
      head: "Access To Community",
      text: "Integration into the HHY elderly network for community programs.",
    },
  ];

  const arr1 = [
    {
      arrImg: img10,
      head: "Nutrition & Swallow Therapy",
      text: "Specialized dietary plans and assistance with swallowing difficulties.",
    },
    {
      arrImg: img11,
      head: "Gait, Balance & Mobility Training",
      text: "Exercises to enhance movement and prevent falls.",
    },

    {
      arrImg: img12,
      head: "Visual And Speech Therapy",
      text: "Techniques to improve vision and speech abilities.",
    },
    {
      arrImg: img13,
      head: "Cognitive Behavioral Therapy",
      text: "Psychological treatment focusing on thought patterns and behaviors.",
    },
    {
      arrImg: img14,
      head: "Bladder & Bowel Movement",
      text: "Management strategies for urinary and fecal incontinence.",
    },
    {
      arrImg: img15,
      head: "Respiratory Management & Training",
      text: "Techniques to improve breathing function.",
    },

    {
      arrImg: img16,
      head: "Cardiovascular Fitness",
      text: "Exercises to strengthen the heart and circulatory system.",
    },
    {
      arrImg: img17,
      head: "Skilled Caregiver",
      text: "Proficient assistance tailored to individual needs.",
    },
    {
      arrImg: img18,
      head: "Pain Relief And Management",
      text: "Strategies to alleviate and control discomfort.",
    },
  ];

  const handleClick = () => {
    window.scrollTo(2000, 4000);
  };

  const dropDownArr = [
    {
      label: "What is the cost for availing elderly care services?",
      text: "We custom tailor our services and packages as per the patient’s need, assessment findings, duration of the service etc.  Please call our team for personalised quotes and estimates, most suitable to your needs.",
    },
    {
      label: "Can your caregiver handle acute emergency events at home?",
      text: "All our caregivers are trained regularly on First Aid and CPR to handle emergency situations at home. You can visit about the same at our SAFETY TRAINING module for the level of training that we have equipped all our caregivers with.",
    },
    {
      label:
        "What if we want to customise our plans, apart from the one available on the website?",
      text: "You can speak to our care manager and put forth your requirements. We will discuss your needs with our in-house doctor and provide you with a holistic care plan based on the requisites.",
    },
    {
      label: "How soon can we start the services?",
      text: "For best and most optimum solution, a lead time of 24 hours is required for any fulfilment.",
    },
    {
      label: "What if my caregiver gets sick? Will I get a replacement?",
      text: "We will provide an equally trained caregiver to fill in for the time till your designated caregiver resumes work.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <ElderlyCareServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        handleClick={handleClick}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />

      <FaqComponent isMobile={selectIsMobile(state)} arr={dropDownArr} />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
    </>
  );
};

export default ElderlyCareServiceContainer;
