import React, { useEffect, useState } from "react";
import img2 from "../../assets/elderlyCareImages/elderly care bg.jpg";
import "./ElderlyCareServicePage.css";
import whyChooseUsImg from "../../assets/homePageImages/whyChooseUs/Why choose us.svg";
import whyChooseUsImg1 from "../../assets/homePageImages/why choose us final mobile.svg";
import img1 from "../../assets/elderlyCareImages/image 100.svg";
import img111 from "../../assets/elderlyCareImages/image 100.jpg";
import bg1 from "../../assets/elderlyCareImages/bg1.png";
import bg2 from "../../assets/elderlyCareImages/bg1.png";
import bg5 from "../../assets/elderlyCareImages/bg.png";
import checkboxImg from "./Frame.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import bg4 from "../../assets/elderlyCareImages/elderlyBg.png";
import mobileImgMain from "../../assets/elderlyCareImages/Frame 427319563 (1).jpg";
import commparision from "./Frame 427320003.svg";
import bg6 from "../../assets/elderlyCareImages/Rectangle 2445.svg";
import bg7 from "./Rectangle 2497.png";
import img9 from "../../assets/elderlyCareImages/caremodel/tech.png";
import img10 from "../../assets/elderlyCareImages/caremodel/security.png";
import img11 from "../../assets/elderlyCareImages/caremodel/service1.png";
import img13 from "../../assets/elderlyCareImages/caremodel/tech.svg";
import img14 from "../../assets/elderlyCareImages/caremodel/emergency.svg";
import img15 from "../../assets/elderlyCareImages/caremodel/service.svg";
import img16 from "../../assets/elderlyCareImages/caremodel/engagement.svg";
import img17 from "../../assets/elderlyCareImages/caremodel/engagement1.png";
import img18 from "../../assets/elderlyCareImages/caremodel/blue.png";
import img19 from "../../assets/elderlyCareImages/caremodel/purple.png";
import img20 from "../../assets/elderlyCareImages/caremodel/green.png";
import img21 from "../../assets/elderlyCareImages/caremodel/red.png";
import mobBg1 from "../../assets/elderlyCareImages/caremodel/Frame 427320013.png";
import mobBg2 from "../../assets/elderlyCareImages/caremodel/Frame 427320014.png";
import mobBg3 from "../../assets/elderlyCareImages/caremodel/Frame 427320010.png";
import mobBg4 from "../../assets/elderlyCareImages/caremodel/Frame 427320015.png";
import ab1 from "./Frame 427320025.svg";
import ab2 from "./Frame 427320024.svg";
import ab3 from "./Frame 427320006.svg";
import ab4 from "./Frame 427320027.svg";

const ElderlyCareServicePage = (props) => {
  const { isMobile, arr, arr1, handleClick, handleEnquireNow } = props;

  const [active, setActive] = useState(true);

  const handleToggle = () => {
    setActive(!active);
    const enqElement = document.querySelector(".elderlyCarePageContainer3");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const dataArr = [
    {
      capsule: img20,
      bgImg: img13,
      mobImg: mobBg1,
      imgIcon: img9,
      bgcolr: "#F0FFD2",
      head: "Technology",
      text: "Integrated features and devices at HHY keep you informed about your health constantly.",
    },
    {
      capsule: img21,
      bgImg: img14,
      mobImg: mobBg2,
      imgIcon: img10,
      bgcolr: "#F1CCCC",
      head: "Security",
      text: "Ensuring safety and an environment conducive to prevention and recovery is our goal.",
    },
    {
      capsule: img18,
      bgImg: img15,
      mobImg: mobBg3,
      imgIcon: img11,
      bgcolr: "#CFF4F9",
      head: "Service",
      text: "Our best-trained caregivers meet all patient needs with expertise and compassion.",
    },
    {
      capsule: img19,
      bgImg: img16,
      mobImg: mobBg4,
      imgIcon: img17,
      bgcolr: "#D3D3ED",
      head: "Engagement",
      text: "Surrounding you with like-minded individuals for sharing experiences and advice to feel heard.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(true);

  const handleRightClick = () => {
    setAnimate(false);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % dataArr.length);
      setAnimate(true);
    }, 50);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % dataArr.length);
        setAnimate(true);
      }, 50);
    }, 2000);

    return () => clearInterval(interval);
  }, [dataArr.length]);

  // const handleLeftClick = () => {
  //   setAnimate(true);
  //   setTimeout(() => {
  //     setCurrentIndex((prevIndex) => {
  //       const newIndex = prevIndex - 1;
  //       return newIndex < 0 ? dataArr.length - 1 : newIndex;
  //     });
  //     setAnimate(false);
  //   }, 50);
  // };

  const crossArr = [ab2, ab3, ab4];

  const [startX, setStartX] = useState(null);
  const [currIndex, setcurrIndex] = useState(0);

  const handleTouchStart = (event) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    if (!startX) return;

    const currentX = event.touches[0].clientX;
    const deltaX = currentX - startX;

    if (deltaX > 50 && currIndex > 0) {
      setcurrIndex((prevIndex) => prevIndex - 1);
      setStartX(null);
    } else if (deltaX < -50 && currIndex < crossArr.length - 2) {
      setcurrIndex((prevIndex) => prevIndex + 1);
      setStartX(null);
    }
  };

  const renderImages = () => {
    const imagesToDisplay = crossArr.slice(currIndex, currIndex + 2);

    return imagesToDisplay.map((imageUrl, index) => (
      <img key={index} src={imageUrl} alt={`Image ${currIndex + index + 1}`} />
    ));
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Elderly Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Healthy You, we prioritize <br /> comprehensive elder care
              that extends beyond <br /> mere physical assistance.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Elderly Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              At Heka Healthy You, we prioritize comprehensive elder care that
              extends beyond mere <br /> physical assistance.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img src={isMobile ? whyChooseUsImg1 : whyChooseUsImg} />

      <div className="elderlyCarePageContainer2">
        <img alt="images" src={isMobile ? img111 : img1} width="100%" />

        <div className="elderlyCarePageContainer2-div">
          <div className="elderlyCarePageContainer2-div1">
            <img alt="images" src={bg1} />

            <div className="elderlyCarePageContainer2-div2">
              <h4 className="elderlyCarePageContainer2-div-h">
                Basic Care Plan
              </h4>
              <p className="elderlyCarePageContainer2-div-t">
                We provide tender attention, aid with daily tasks, ensure
                comfort, foster connections, and nurture well-being for
                cherished elders.
              </p>

              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Consultations
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Health manager
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Trained caregivers
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Health profiling
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Emergency Services
                </p>
              </div>
              <p className="elderlyCarePageContainer2-div-t3">and more</p>

              <div className="elderlyCarePageContainer2-btn">
                <button
                  onClick={handleClick}
                  className="elderlyCarePageContainer2-btn1"
                >
                  Compare
                </button>
                <button
                  onClick={() => handleToggle()}
                  className="elderlyCarePageContainer2-btn2"
                >
                  Know more
                </button>
              </div>
            </div>
          </div>

          <div className="elderlyCarePageContainer2-div1">
            <img alt="images" src={bg2} />
            <div className="elderlyCarePageContainer2-div2">
              <h4 className="elderlyCarePageContainer2-div-h">
                Specialised Care Plan
              </h4>
              <p className="elderlyCarePageContainer2-div-t">
                Our tailored expertise addresses unique health challenges with
                compassion and skill, enhancing the quality of life for beloved
                seniors.
              </p>

              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Gait, balance & mobility training
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Health manager
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Skilled Caregiver
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Pain relief and management
                </p>
              </div>
              <div className="elderlyCarePageContainer2-aligncheckbox">
                {/* <Checkbox
                  icon={
                    <CheckCircleIcon
                      htmlColor="#F26828"
                      sx={isMobile? { fontSize: "15px" }:{ fontSize: "24px" }}
                    />
                  }
                  checkedIcon={<CheckCircleIcon />}
                /> */}
                <img
                  src={checkboxImg}
                  style={
                    isMobile
                      ? {
                          width: "16px",
                          height: "16px",
                          borderRadius: "100%",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          borderRadius: "100%",
                        }
                  }
                />
                <p className="elderlyCarePageContainer2-div-t2">
                  Emergency Services
                </p>
              </div>
              <p className="elderlyCarePageContainer2-div-t3">and more</p>

              <div className="elderlyCarePageContainer2-btn">
                <button
                  onClick={handleClick}
                  className="elderlyCarePageContainer2-btn3"
                >
                  Starts INR 11,999/-
                </button>
                <button
                  onClick={() => handleToggle()}
                  className="elderlyCarePageContainer2-btn2"
                >
                  Know more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="elderlyCarePageContainer-toggle">
        <div className="elderlyCarePageContainer-toggle1">
          <button
            onClick={handleToggle}
            className={
              active
                ? "elderlyCarePageContainer-toggleActive"
                : "elderlyCarePageContainer-toggleUnActive"
            }
          >
            Basic
          </button>
          <button
            onClick={handleToggle}
            className={
              !active
                ? "elderlyCarePageContainer-toggleActive"
                : "elderlyCarePageContainer-toggleUnActive"
            }
          >
            Specialised
          </button>
        </div>
      </div>

      {active ? (
        <div
          // style={{ position: "relative" }}
          className="elderlyCarePageContainer3"
        >
          {isMobile ? (
            <img
              alt="images"
              src={bg5}
              className="elderlyCarePageContainer3-img1"
            />
          ) : (
            <img
              alt="images"
              src={bg4}
              className="elderlyCarePageContainer3-img1"
            />
          )}

          {arr.map((item, index) => {
            return (
              <div key={index} className="elderlyCarePageContainer3-card">
                <div className="elderlyCarePageContainer3-cardB1"></div>
                <div className="elderlyCarePageContainer3-cardB2"></div>
                <div className="elderlyCarePageContainer3-cardB3"></div>
                <div className="elderlyCarePageContainer3-cardB4"></div>
                <div>
                  <img
                    alt="images"
                    src={item.arrImg}
                    className="elderlyCarePageContainer3-cardImg"
                  />
                </div>

                <h5 className="elderlyCarePageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="elderlyCarePageContainer3-cardText2">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="elderlyCarePageContainer3">
          {isMobile ? (
            <img
              alt="images"
              src={bg5}
              className="elderlyCarePageContainer3-img1"
            />
          ) : (
            <img
              alt="images"
              src={bg4}
              className="elderlyCarePageContainer3-img1"
            />
          )}
          {arr1.map((item, index) => {
            return (
              <div key={index} className="elderlyCarePageContainer3-card">
                <div className="elderlyCarePageContainer3-cardB1"></div>
                <div className="elderlyCarePageContainer3-cardB2"></div>
                <div className="elderlyCarePageContainer3-cardB3"></div>
                <div className="elderlyCarePageContainer3-cardB4"></div>
                <div>
                  <img
                    alt="images"
                    src={item.arrImg}
                    className="elderlyCarePageContainer3-cardImg"
                  />
                </div>

                <h5 className="elderlyCarePageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="elderlyCarePageContainer3-cardText2">
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
      )}

      <div className="elderlyCarePageContainer4">
        {isMobile ? (
          <>
            <div
              style={{
                position: "relative",
                padding: "16px 0px",
                display: "flex",
              }}
            >
              <div>
                <img src={ab1} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: "0px",
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={() => setStartX(null)}
              >
                {renderImages()}
              </div>
            </div>
          </>
        ) : (
          <img alt="images" src={commparision} />
        )}
      </div>

      <button
        style={
          isMobile
            ? { display: "block", margin: "auto", width: "180px" }
            : { display: "block", margin: "auto", width: "340px" }
        }
        className="consultationPageContainer1Content-btn"
        onClick={handleEnquireNow}
      >
        Enquire Now
      </button>

      <div className="elderlyCarePageContainer5">
        <img
          alt="images"
          src={isMobile ? bg7 : bg6}
          style={{ width: "100%" }}
        />
        <div className="elderlyCarePageContainer5-TextMain">
          {isMobile ? (
            <>
              HHY offers all answers and future services <br /> within one
              platform, eliminating the need to <br /> go elsewhere.
            </>
          ) : (
            <>
              HHY offers all answers and future services within one platform,
              <br />
              eliminating the need to go elsewhere.
            </>
          )}
        </div>
        <div className="elderlyCarePageContainer5-HeadMain">Our Care Model</div>
        {isMobile ? (
          <div
            onClick={handleRightClick}
            className="elderlyCarePageContainer5-div"
          >
            <img
              alt="images"
              src={dataArr[currentIndex].mobImg}
              className={animate ? "center-animation1" : ""}
              style={{ width: "84%" }}
            />

            <div className="elderlyCarePageContainer5-div1">
              <div className="vertical-capsule">
                <img src={dataArr[currentIndex].capsule} className="capsule" />

                <img
                  alt="images"
                  src={dataArr[currentIndex].imgIcon}
                  className={animate ? "center-animation" : ""}
                />
              </div>
            </div>
            <div className="elderlyCarePageContainer5-div2">
              <div className={animate ? "center-animationHead" : ""}>
                {dataArr[currentIndex].head}
              </div>
              <div className={animate ? "center-animationText" : ""}>
                {dataArr[currentIndex].text}
              </div>
            </div>
          </div>
        ) : (
          <div className="elderlyCarePageContainer5-div">
            <img
              alt="images"
              src={dataArr[currentIndex].bgImg}
              className={animate ? "center-animation1" : ""}
            />
            <div className="elderlyCarePageContainer5-div1">
              <div className="vertical-capsule">
                <img src={dataArr[currentIndex].capsule} className="capsule" />

                <img
                  alt="images"
                  src={dataArr[currentIndex].imgIcon}
                  className={animate ? "center-animation" : ""}
                />
              </div>
            </div>
            <div
              onClick={handleRightClick}
              className="elderlyCarePageContainer5-div2"
            >
              <div className={animate ? "center-animationHead" : ""}>
                {dataArr[currentIndex].head}
              </div>
              <div className={animate ? "center-animationText" : ""}>
                {dataArr[currentIndex].text}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ElderlyCareServicePage;
