import React, { useContext, useEffect, useState } from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { APIGetRequest, APIPostRequest } from "../../config/HttpClient";
import EventsPage from "../../components/EventsPage/Events";
import images from "../../commons/ImagesExport";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#ffff",
  },
  progressbar: {
    color: "#3F6B68",
  },
}));

const GalleryPageContainer = () => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [eventsData, setEventsData] = useState();

  const monthMapping = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  useEffect(() => {
    setIsFetching(true);
    APIGetRequest(
      "/fetchAllEvents.php",
      (res) => {
        let response = res.events;
        for (let i = 0; i < response.upcomingEvents.length; i++) {
          response.upcomingEvents[i].startDate = `${
            response.upcomingEvents[i].startDate.split("-")[2]
          } ${
            monthMapping[
              Number(response.upcomingEvents[i].startDate.split("-")[1])
            ]
          } ${response.upcomingEvents[i].startDate.split("-")[0]}`;
        }
        for (let i = 0; i < response.pastEvents.length; i++) {
          response.pastEvents[i].startDate = `${
            response.pastEvents[i].startDate.split("-")[2]
          } ${
            monthMapping[Number(response.pastEvents[i].startDate.split("-")[1])]
          } ${response.pastEvents[i].startDate.split("-")[0]}`;
        }
        console.log(response);
        setEventsData(response);
        setIsFetching(false);
      },
      (err) => {
        setIsFetching(false);
        if (err.code === "404") {
          console.log("error 404", err);
        }
        console.log(err);
      },
      {}
    );
  }, []);

  return (
    <>
      {isFetching ? (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <img src={images.loader} />
        </Backdrop>
      ) : (
        eventsData && <EventsPage eventsData={eventsData} />
      )}
    </>
  );
};

export default GalleryPageContainer;
