import React from "react";
import img2 from "../../assets/operativeCareImages/operative care bg.jpg";
import "./OperativeCareServicePage.css";
import whyChooseUsImg from "../../assets/homePageImages/whyChooseUs/Why choose us.svg";
import img3 from "../../assets/operativeCareImages/operative 1.svg";
import img4 from "../../assets/operativeCareImages/operative 2.svg";
import img5 from "../../assets/operativeCareImages/operative 3.svg";
import img6 from "../../assets/operativeCareImages/operative 4.svg";
import whyChooseUsImg1 from "../../assets/homePageImages/why choose us final mobile.svg";
import mobileImgMain from "../../assets/operativeCareImages/Frame 427319565.jpg";
import dadi from "../../assets/homePageImages/Vector.svg";
const OperativeCareServicePage = (props) => {
  const { isMobile, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Operative Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              Operative care refers to the comprehensive support <br /> and
              medical attention provided to individuals <br />
              undergoing surgical procedures.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Operative Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              Operative care refers to the comprehensive support and medical
              attention provided to <br /> individuals undergoing surgical
              procedures.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img src={isMobile ? whyChooseUsImg1 : whyChooseUsImg} />

      <h4
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMain"
      >
        Our Services
      </h4>

      {isMobile ? (
        <div className="operativeCarePageContainer3">
          <img src={img3} />
          <img src={img5} />
          <img src={img4} />
          <img src={img6} />
        </div>
      ) : (
        <div className="operativeCarePageContainer3">
          <img src={img3} />
          <img src={img4} />
          <img src={img5} />
          <img src={img6} />
        </div>
      )}
    </>
  );
};

export default OperativeCareServicePage;
