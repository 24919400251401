import React, { useContext, useEffect } from "react";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import AboutUsPage from "../../components/AboutUsPage/AboutUsPage";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
const AboutUsContainer = () => {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropDownArr = [
    {
      label: "What does HHY offer?",
      text: "We offer preventive and curative solutions, including elderly care, pregnancy care, operative care, and medical astrology, prioritising your well-being.",
    },
    {
      label: "How does HHY function?",
      text: "We provide patient-engaged, personalised, and tech-progressive care, ensuring comfort, empathy, and comprehensive medical expertise for optimal outcome.",
    },
    {
      label: "How can I start your services?",
      text: "Contact us to begin your journey toward a healthier, happier you. Our team will guide you through the process.",
    },
    {
      label: "Why choose home health care?",
      text: "Home health care prioritises convenience, comfort, and personalised support, ensuring your unique needs are met with empathy and expertise.",
    },
    {
      label: "What all data does HHY store? Is it Safe?",
      text: "We store essential medical data securely, adhering to strict safety protocols to ensure confidentiality and peace of mind for our clients.",
    },
  ];


  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <AboutUsPage isMobile={selectIsMobile(state)} />
      <MobileFloatingButton isMobile={selectIsMobile(state)} />
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default AboutUsContainer;
