import React, { createRef, useEffect, useState } from "react";
import img from "../../../assets/homePageImages/home page bg.jpg";
import mobileImgMain from "../../../assets/homePageImages/Frame 427319584.jpg";
import "./HomePage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EastIcon from "@mui/icons-material/East";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import img1 from "../../../assets/image/Rectangle 2546.svg";
import img2 from "../../../assets/image/Rectangle 2546 (1).svg";
import img3 from "../../../assets/image/CPR-Training.jpeg";
import whyChooseUsImg from "../../../assets/homePageImages/whyChooseUs/Why choose us.svg";
import whyChooseUsImg1 from "./why choose us final mobile.png";
import blueBg from "../../../assets/homePageImages/downloads/app download bg image.avif";
import mobileImg from "../../../assets/homePageImages/downloads/android mockup for app.png";
import googlePlayImg from "../../../assets/homePageImages/footer/image 105.svg";
import applePlayImg from "../../../assets/homePageImages/footer/image 104.svg";
import h1 from "../../../assets/homePageImages/Highlights/1. Training at Border Road Organization, New Delhi/DSC_4478.JPG";
import h2 from "../../../assets/homePageImages/Highlights/1. Training at Border Road Organization, New Delhi/DSC_4515.JPG";
import h3 from "../../../assets/homePageImages/Highlights/1. Training at Border Road Organization, New Delhi/DSC_4562.JPG";
import h4 from "../../../assets/homePageImages/Highlights/1. Training at Border Road Organization, New Delhi/DSC_4614.JPG";
import h5 from "../../../assets/homePageImages/Highlights/1. Training at Border Road Organization, New Delhi/Main.JPG";
import h6 from "../../../assets/homePageImages/Highlights/2. Training of Zomato_s Delivery Partners/IMG-20240212-WA0011.jpg";
import h7 from "../../../assets/homePageImages/Highlights/2. Training of Zomato_s Delivery Partners/Main.jpg";
import h9 from "../../../assets/homePageImages/Highlights/2. Training of Zomato_s Delivery Partners/WhatsApp Image 2024-03-11 at 3.27.33 PM.jpeg";
import h10 from "../../../assets/homePageImages/Highlights/3. Training at National Health Authority, New Delhi/DSC_4384.JPG";
import h11 from "../../../assets/homePageImages/Highlights/3. Training at National Health Authority, New Delhi/DSC_4401.JPG";
import h12 from "../../../assets/homePageImages/Highlights/3. Training at National Health Authority, New Delhi/IMG20231013112653.jpg";
import h13 from "../../../assets/homePageImages/Highlights/3. Training at National Health Authority, New Delhi/IMG20231013113051.jpg";
import h14 from "../../../assets/homePageImages/Highlights/3. Training at National Health Authority, New Delhi/Main.JPG";
import h15 from "../../../assets/homePageImages/Highlights/4. Training of Smile Foundation_s Front Line Workers/DSC_4621.JPG";
import h16 from "../../../assets/homePageImages/Highlights/4. Training of Smile Foundation_s Front Line Workers/DSC_4639.JPG";
import h17 from "../../../assets/homePageImages/Highlights/4. Training of Smile Foundation_s Front Line Workers/IMG-20240217-WA0006.jpg";
import h18 from "../../../assets/homePageImages/Highlights/4. Training of Smile Foundation_s Front Line Workers/IMG-20240217-WA0028.jpg";
import h19 from "../../../assets/homePageImages/Highlights/4. Training of Smile Foundation_s Front Line Workers/Main.jpg";
import dadi from "../../../assets/homePageImages/Vector.svg";
import h20 from "../../../assets/homePageImages/Highlights/5. Training at AICTE, New Delhi/1S0A5561.JPG";
import h21 from "../../../assets/homePageImages/Highlights/5. Training at AICTE, New Delhi/Copy of 1S0A5644.JPG";
import h22 from "../../../assets/homePageImages/Highlights/5. Training at AICTE, New Delhi/Copy of 1S0A5653.JPG";
import h23 from "../../../assets/homePageImages/Highlights/5. Training at AICTE, New Delhi/Copy of 1S0A5730.JPG";
import h24 from "../../../assets/homePageImages/Highlights/5. Training at AICTE, New Delhi/Main.JPG";
import menu from "../../../assets/homePageImages/Frame.svg";
import h25 from "../../../assets/homePageImages/Highlights/6. Training at Willow Tree Smart School, Panchkula/DSC_3561.JPG";
import h26 from "../../../assets/homePageImages/Highlights/6. Training at Willow Tree Smart School, Panchkula/DSC_3571.JPG";
import h27 from "../../../assets/homePageImages/Highlights/6. Training at Willow Tree Smart School, Panchkula/DSC_3609.JPG";
import h28 from "../../../assets/homePageImages/Highlights/6. Training at Willow Tree Smart School, Panchkula/DSC_3616.JPG";
import h29 from "../../../assets/homePageImages/Highlights/6. Training at Willow Tree Smart School, Panchkula/Main.JPG";
import bg1 from "../../../assets/homePageImages/bg.png";
import bg2 from "../../../assets/homePageImages/bg1.png";
import h30 from "../../../assets/homePageImages/Highlights/7. Training of Homeopathic Doctors/DSC_3960 - Copy (2).JPG";
import h31 from "../../../assets/homePageImages/Highlights/7. Training of Homeopathic Doctors/DSC_4033 (1).JPG";
import h32 from "../../../assets/homePageImages/Highlights/7. Training of Homeopathic Doctors/DSC_4205.JPG";
import h33 from "../../../assets/homePageImages/Highlights/7. Training of Homeopathic Doctors/DSC_4226.JPG";
import h34 from "../../../assets/homePageImages/Highlights/7. Training of Homeopathic Doctors/Main.JPG";

import h35 from "../../../assets/homePageImages/Highlights/8. Training at Rao Tula Ram Memorial Hospital, Delhi/Copy of DSC_3759.JPG";
import h36 from "../../../assets/homePageImages/Highlights/8. Training at Rao Tula Ram Memorial Hospital, Delhi/Copy of DSC_3783.JPG";
import h37 from "../../../assets/homePageImages/Highlights/8. Training at Rao Tula Ram Memorial Hospital, Delhi/DSC_3741.JPG";
import h38 from "../../../assets/homePageImages/Highlights/8. Training at Rao Tula Ram Memorial Hospital, Delhi/DSC_3743.JPG";
import h39 from "../../../assets/homePageImages/Highlights/8. Training at Rao Tula Ram Memorial Hospital, Delhi/Main.JPG";
import ContactUsContainer from "../../../shared/containers/ContactUsContainer/ContactUsContainer";

const HomePage = (props) => {
  const mySlider = createRef();
  const mySlider1 = createRef();
  const {
    serviceCardsData,
    mobileNumber,
    handleMobile,
    isMobile,
    handleReadMore,
    pageIndex,
  } = props;
  // const heights = (window.innerHeight * 4.5) / 5.5;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide1, setCurrentSlide1] = useState(0);

  const setting = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    centerMode: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const setting1 = {
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    // autoplay: true,
    centerMode: true,
    centerPadding: "0",
    beforeChange: (oldIndex, newIndex) => setCurrentSlide1(newIndex),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imagess = [
    {
      images: img1,
      heading: "Elderly Care",
      author: "Parul Singh",
      text: "All of my parents' needs were covered under one roof. It was convenient and assuring to take services from Heka.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Ajay Kumar",
      text: "HHY was quick to act on my needs. I was able to handpick services that were of need to me.",
    },
    {
      images: img1,
      heading: "Elderly Care",
      author: "Akash Jain",
      text: "The caregiver was well qualified to take care of my mother. I was happy to take support of HHY while I was away from home.",
    },
    {
      images: img3,
      heading: "Safety Training",
      author: "Dr. Rashmi Chandwani",
      text: "I had attended a training on CPR. It was excellent and the trainers were excellent at explaining. They make sure that you learn it properly.",
    },
    {
      images: img3,
      heading: "Safety Training",
      author: "Ravi Bhardwaj",
      text: "This live saving training program is a must for every citizen of this country who can learn and perform the same in critical medical situations in life.",
    },
    {
      images: img3,
      heading: "Safety Training",
      author: "Sonia Chhonkar",
      text: "It was an excellent learning experience. Training was precise & clear. Trainers were very knowledgeable and helpful in clearing doubts.",
    },
  ];

  const alignImages = () => {
    return serviceCardsData.map((item, index) => {
      const isCenter = index === currentSlide;

      const style1 = isCenter ? { width: "110%" } : { width: "100%" };
      const style2 = isCenter ? { width: "100%" } : { width: "100%" };
      return (
        <>
          <div
            key={index}
            className={isCenter ? "homePageImgDiv1A" : "homePageImgDiv1U"}
            style={isMobile ? style2 : style1}
          >
            <img
              key={index}
              src={item.images}
              alt="images"
              className="img-fluid"
              style={{ borderRadius: "16px", height: "240" }}
            />
            <p
              className={
                isCenter ? "homePageImgTextabcd" : "homePageImgTextabcd1"
              }
            >
              {item.heading}
            </p>
            <p className="homePageImgText1">{item.text}</p>
            <button
              className={isCenter ? "homePageImgBtn1Ac" : "homePageImgBtn1"}
              onClick={() => handleReadMore(item)}
            >
              Read More
            </button>
          </div>
        </>
      );
    });
  };

  const alignImages1 = () => {
    return imagess.map((item, index) => {
      const isCenter = index === currentSlide1;
      return (
        <>
          {isMobile ? (
            <>
              <div className="homePageContainer4-mainBox">
                <img src={item.images} className="homePageContainer4-img" />
                <div className="homePageContainer4-boxA">
                  <div className="homePageContainer4-alignHead">
                    <div className="homePageContainer4-head">
                      {item.heading}
                    </div>
                    <div className="homePageContainer4-dot"></div>
                    <div className="homePageContainer4-head">{item.author}</div>
                  </div>
                  <p className="homePageContainer4-text">{item.text}</p>
                </div>
              </div>
            </>
          ) : (
            <>
              {isCenter ? (
                <>
                  <div className="homePageContainer4-mainBox">
                    <img src={item.images} className="homePageContainer4-img" />
                    <div className="homePageContainer4-boxA">
                      <div className="homePageContainer4-alignHead">
                        <div className="homePageContainer4-head">
                          {item.heading}
                        </div>
                        <div className="homePageContainer4-dot"></div>
                        <div className="homePageContainer4-head">
                          {item.author}
                        </div>
                      </div>
                      <p className="homePageContainer4-text">{item.text}</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="homePageContainer4-boxU">
                  <div className="homePageContainer4-alignHead">
                    <div className="homePageContainer4-head">
                      {item.heading}
                    </div>
                    <div className="homePageContainer4-dot"></div>
                    <div className="homePageContainer4-head">{item.author}</div>
                  </div>
                  <p className="homePageContainer4-text">{item.text}</p>
                </div>
              )}
            </>
          )}
        </>
      );
    });
  };

  const handleNext1 = () => {
    mySlider.current.slickNext();
  };

  const handlePrev1 = () => {
    mySlider.current.slickPrev();
  };

  const handleNext2 = () => {
    mySlider1.current.slickNext();
  };

  const handlePrev2 = () => {
    mySlider1.current.slickPrev();
  };

  const images = [
    {
      cards: [h1, h2, h3, h4, h5],
      cardText: "Training at Border Road Organization, New Delhi",
    },
    {
      cards: [h6, h7, h9],
      cardText: "Training of Zomato_s Delivery Partners",
    },

    {
      cards: [h10, h11, h12, h13, h14],
      cardText: "Training at National Health Authority, New Delhi",
    },
    {
      cards: [h15, h16, h17, h18, h19],
      cardText: "Training of Smile Foundation_s Front Line Workers",
    },
    {
      cards: [h20, h21, h22, h23, h24],
      cardText: "Training at AICTE, New Delhi",
    },
    {
      cards: [h25, h26, h27, h28, h29],
      cardText: "Training at Willow Tree Smart School, Panchkula",
    },

    {
      cards: [h30, h31, h32, h33, h34],
      cardText: "Training of Homeopathic Doctors",
    },
    {
      cards: [h35, h36, h37, h38, h39],
      cardText: "Training at Rao Tula Ram Memorial Hospital, Delhi",
    },
  ];

  const [imgIndex, setImgIndex] = useState(0);

  const handleLeft = () => {
    if (imgIndex > 0) {
      setImgIndex(imgIndex - 1);
    }
  };

  const handleRight = () => {
    if (imgIndex < images.length - 1) {
      setImgIndex(imgIndex + 1);
    }
  };

  // const colrArr = ["green", "red", "blue", "grey"];

  // const [colIn, setColIndex] = useState(0);

  // const handleLeftClick = () => {
  //   if (colIn > 0) {
  //     setColIndex(colIn - 1);
  //   }
  //   const button = document.querySelector(".testMain");
  //   button.classList.add("circular");
  // };

  // const handleRightClick = () => {
  //   if (colIn < colrArr.length - 1) {
  //     setColIndex(colIn + 1);
  //   }
  //   const button = document.querySelector(".testMain");
  //   button.classList.add("circular");
  // };

  return (
    <>
      {/* <div style={{ backgroundColor: colrArr[colIn] }} className="test">
        <div onClick={() => handleLeftClick()} className="test1"></div>
        <div className="test2">
          <button
            className="testMain"
            style={{ backgroundColor: colrArr[colIn] }}
          ></button>
        </div>
        <div onClick={() => handleRightClick()} className="test3"></div>
      </div> */}

      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Healthy You at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Welcome to Heka Healthy You! We don’t just care about you today,
              but also help shape your tomorrow towards a healthier, happier
              you.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Healthy You at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Welcome to Heka Healthy You! We don’t just care about you today,
              but also help shape <br /> your tomorrow towards a healthier,
              happier you.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div className="homePageContainer4-headMainCont">
        <h4 className="homePageContainer4-headMain">Our Services</h4>
        {isMobile ? (
          <></>
        ) : (
          <img className="homePageContainer4-headMain-dadi" src={dadi} />
        )}
      </div>
      <div className="homePageContainer2">
        <Slider ref={mySlider} arrows={false} {...setting}>
          {alignImages()}
        </Slider>
        <div className="homePageContainer2-arrow1">
          <EastIcon
            sx={isMobile ? { fontSize: "1.5rem" } : { fontSize: "1.5rem" }}
            onClick={handlePrev1}
          />
        </div>
        <div className="homePageContainer2-arrow2">
          <EastIcon
            sx={isMobile ? { fontSize: "1.5rem" } : { fontSize: "1.5rem" }}
            onClick={handleNext1}
          />
        </div>
      </div>

      <img src={isMobile ? whyChooseUsImg1 : whyChooseUsImg} />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      {/* <div className="homePageContainer-downloads">
        <img src={blueBg} className="homePageContainer-downloadImg1" />
        <img src={mobileImg} className="homePageContainer-downloadImg2" />
        <div className="homePageContainer-downloads1">
          <p className="homePageContainer-downloadhead">
            Receive a link to download the app
          </p>
          <div className="homePageContainer-downloadAlign">
            <div
              style={
                isMobile
                  ? {
                      backgroundColor: "#fff",
                      height: "60%",
                      display: "flex",
                      justifyContent: "flex-start",
                      borderRadius: "0.2rem",
                    }
                  : { backgroundColor: "#fff", height: "100%" }
              }
              className={"contactUsPage-formInput1Div"}
            >
              <div className="contactUsPage-formInput1Div1AB">
                <div
                  style={isMobile ? { fontSize: "8px" } : {}}
                  className="contactUsPage-formInput1Div-code"
                >
                  +91
                </div>
                <ArrowDropDownIcon
                  htmlColor="#838383"
                  sx={isMobile ? { fontSize: "10px" } : {}}
                />
              </div>
              <input
                type="text"
                maxLength={10}
                value={mobileNumber}
                onChange={handleMobile}
                className="contactUsPage-formInput1AB"
                style={isMobile ? { fontSize: "8px" } : {}}
                // style={{ width: "20rem" }}
              />
            </div>
            <button className="homePageContainer-downloadBtn">Submit</button>
          </div>

          <div className="homePageContainer-downloadIcons">
            <img
              className="homePageContainer-downloadIcons1"
              src={applePlayImg}
            />
            <img
              className="homePageContainer-downloadIcons1"
              src={googlePlayImg}
            />
          </div>
        </div>
      </div> */}

      <div className="homePageContainer4-headMainCont">
        <h4 className="homePageContainer4-headMain">Testimonials</h4>
        {isMobile ? (
          <></>
        ) : (
          <img className="homePageContainer4-headMain-dadi" src={dadi} />
        )}
      </div>
      <div className="homePageContainer4">
        <Slider ref={mySlider1} arrows={false} {...setting1}>
          {alignImages1()}
        </Slider>
        <div className="homePageContainer4-arrow1">
          <EastIcon
            sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
            onClick={handlePrev2}
          />
        </div>
        <div className="homePageContainer4-arrow2">
          <EastIcon
            sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
            onClick={handleNext2}
          />
        </div>

        {isMobile ? (
          <img
            alt="images"
            src={bg2}
            className="elderlyCarePageContainer3-img1"
          />
        ) : (
          <img
            alt="images"
            src={bg1}
            className="elderlyCarePageContainer3-img1"
          />
        )}
      </div>

      <div className="homePageContainer4-headMainCont">
        <h4 className="homePageContainer4-headMain">Highlights</h4>
        {isMobile ? (
          <></>
        ) : (
          <img className="homePageContainer4-headMain-dadi1" src={dadi} />
        )}
      </div>
      <div className="lpImgContainer">
        <div onClick={() => handleLeft()} className="homePageContainer4-arrow3">
          <EastIcon
            sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
          />
        </div>
        <div
          onClick={() => handleRight()}
          className="homePageContainer4-arrow4"
        >
          <EastIcon
            sx={isMobile ? { fontSize: "1rem" } : { fontSize: "1.5rem" }}
          />
        </div>
        <div className="lpImgCo">
          {images.map((item, index) => {
            return (
              <>
                <div className="lpImgCo1">
                  {imgIndex === index && (
                    <Slider
                      slidesToScroll={1}
                      dots={true}
                      autoplay={true}
                      arrows={false}
                      speed={200}
                    >
                      {item.cards.map((it, index) => {
                        return (
                          <img
                            key={index}
                            src={it}
                            alt="images"
                            className="lpImg"
                          />
                        );
                      })}
                    </Slider>
                  )}
                </div>
              </>
            );
          })}
          <div className="lpImgCoText">{images[imgIndex].cardText}</div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
