import React from 'react'
import images from '../../../commons/ImagesExport'
import Slider from 'react-slick'
import './Testimonials.css'

function Testimonials() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 10000,
        centerPadding: "15px",
        cssEase: "linear",
        arrows: false,
    
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
      const settings2 = {
        ...settings,
        slidesToShow: 4,
        autoplaySpeed: 2000,
      };
  return (
    <div className="container">
        <div className="first-slider">
          <h3 className="sliderheadingtest">WHAT OUR CLIENTS SAY ABOUT US</h3>
          <Slider {...settings2} className="flex justify-center items-center">
            <div>
              <div className="testimonial-main">
                <div className="photo-client-main">
                  <div className="photo-client">
                    <img src={images.BoyTesti} alt="" className="client-pic" />
                  </div>
                  <div className="feed-back">
                    <h6 className="cline-name">Mahesh Yadav</h6>
                    <ul className="rating">
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 className="feed-testi-heading">Friendly Staff</h6>
                <p className="feed-testi-para">
                  I can't say enough good things about Heka Healthy You—they
                  took such good care of my parents. The staff is extremely
                  friendly and takes the time to learn about your needs and
                  match you with the best caregiver for your parent. We were
                  confident that our parents were in capable, caring hands with
                  them.
                </p>
              </div>
            </div>
            <div>
              <div className="testimonial-main">
                <div className="photo-client-main">
                  <div className="photo-client">
                    <img src={images.GirlTesti} alt="" className="client-pic" />
                  </div>
                  <div className="feed-back">
                    <h6 className="cline-name">Kritika Kaur</h6>
                    <ul className="rating">
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 className="feed-testi-heading">
                  Best elderly care program
                </h6>
                <p className="feed-testi-para">
                  I've been using Heka Healthy You for six months and they're
                  fantastic. They are easy to work with and have an amazing
                  staff. My mother-in-caregiver law's genuinely enjoys working
                  with her and is extremely patient and kind. If you require
                  family caregiving, I strongly recommend Heka Healthy You.
                </p>
              </div>
            </div>
            <div>
              <div className="testimonial-main">
                <div className="photo-client-main">
                  <div className="photo-client">
                    <img src={images.GirlTesti} alt="" className="client-pic" />
                  </div>
                  <div className="feed-back">
                    <h6 className="cline-name">Sneha Bakshi</h6>
                    <ul className="rating">
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 className="feed-testi-heading">Excellent Service</h6>
                <p className="feed-testi-para">
                  I enjoy collaborating with Heka Healthy You. They have
                  excellent caregivers who truly understand and can meet the
                  needs of their clients. The staff is dedicated to their
                  mission and always goes above and beyond.
                </p>
              </div>
            </div>
            <div>
              <div className="testimonial-main">
                <div className="photo-client-main">
                  <div className="photo-client">
                    <img src={images.BoyTesti} alt="" className="client-pic" />
                  </div>
                  <div className="feed-back">
                    <h6 className="cline-name">Mahesh Yadav</h6>
                    <ul className="rating">
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 className="feed-testi-heading">Friendly Staff</h6>
                <p className="feed-testi-para">
                  I can't say enough good things about Heka Healthy You—they
                  took such good care of my parents. The staff is extremely
                  friendly and takes the time to learn about your needs and
                  match you with the best caregiver for your parent. We were
                  confident that our parents were in capable, caring hands with
                  them.
                </p>
              </div>
            </div>
            <div>
              <div className="testimonial-main">
                <div className="photo-client-main">
                  <div className="photo-client">
                    <img src={images.GirlTesti} alt="" className="client-pic" />
                  </div>
                  <div className="feed-back">
                    <h6 className="cline-name">Kritika Kaur</h6>
                    <ul className="rating">
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.BlankStar} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 className="feed-testi-heading">
                  Best elderly care program
                </h6>
                <p className="feed-testi-para">
                  I've been using Heka Healthy You for six months and they're
                  fantastic. They are easy to work with and have an amazing
                  staff. My mother-in-caregiver law's genuinely enjoys working
                  with her and is extremely patient and kind. If you require
                  family caregiving, I strongly recommend Heka Healthy You.
                </p>
              </div>
            </div>
            <div>
              <div className="testimonial-main">
                <div className="photo-client-main">
                  <div className="photo-client">
                    <img src={images.GirlTesti} alt="" className="client-pic" />
                  </div>
                  <div className="feed-back">
                    <h6 className="cline-name">Sneha Bakshi</h6>
                    <ul className="rating">
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.Star} alt="" />
                      </li>
                      <li>
                        <img src={images.BlankStar} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
                <h6 className="feed-testi-heading">Excellent Service</h6>
                <p className="feed-testi-para">
                  I enjoy collaborating with Heka Healthy You. They have
                  excellent caregivers who truly understand and can meet the
                  needs of their clients. The staff is dedicated to their
                  mission and always goes above and beyond.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
  )
}

export default Testimonials