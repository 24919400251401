import React, { useState, Fragment, useEffect } from "react";
import { Drawer } from "@mui/material";
import "./HeaderDrawer.css";
import logo from "./Group.svg";
import close from "../../../assets/homePageImages/headerDrawer/Frame.svg";
import facebook from "../../../assets/headerIcons/fb1.svg";
import linkedIn from "../../../assets/headerIcons/linekdin1.svg";
import mail from "../../../assets/headerIcons/mail1.svg";
import twitter from "../../../assets/headerIcons/twitter 11.svg";
import youtube from "../../../assets/headerIcons/Frame 4273200201.svg";
import insta from "../../../assets/headerIcons/Frame1.svg";
import headerBg from "../../../assets/homePageImages/headerDrawer/background.svg";
const HeaderDrawer = (props) => {
  const {
    showDrawer,
    handleDrawer,
    handleDrawerClose,
    handleHomePage,
    handleAbout,
    handleBlogs,
    handleElderlyCare,
    handleSafetyCare,
    handlePregnancyCare,
    handleGeneralWellness,
    handleOperativeCare,
  } = props;
  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleDrawer(open);
    setState({
      left: open,
    });
  };

  useEffect(() => {
    setState({
      left: showDrawer,
    });
  }, [showDrawer]);

  const list = () => {
    return (
      <div className="headerDrawerCOntainer">
        <img
          src={headerBg}
          style={{ position: "absolute", zIndex: 0, width: "100%" }}
        />
        <div className="headerDrawerCOntainer-Div">
          <img className="headerDrawerCOntainer-Img" src={logo} />
          <img src={close} onClick={handleDrawerClose} />
        </div>

        <div
          style={{
            height: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div className="headerDrawerCOntainer-Div1">
            <h4
              onClick={() => handleHomePage()}
              className="headerDrawerCOntainer-head1"
            >
              Home
            </h4>
            <h4
              onClick={() => handleAbout()}
              className="headerDrawerCOntainer-head1"
            >
              About Us
            </h4>
            <h4 className="headerDrawerCOntainer-head1">Our Services</h4>

            <p
              onClick={() => handleElderlyCare()}
              className="headerDrawerCOntainer-text"
            >
              Elderly Care
            </p>
            <p
              onClick={() => handlePregnancyCare()}
              className="headerDrawerCOntainer-text"
            >
              Pregnancy Care
            </p>
            <p
              onClick={() => handleSafetyCare()}
              className="headerDrawerCOntainer-text"
            >
              Safety Trainings
            </p>
            {/* <p
              onClick={() => handleGeneralWellness()}
              className="headerDrawerCOntainer-text"
            >
              General Wellness
            </p> */}
            <p
              onClick={handleOperativeCare}
              className="headerDrawerCOntainer-text"
            >
              Operative Care
            </p>

            <h4
              onClick={() => handleBlogs()}
              className="headerDrawerCOntainer-head1"
            >
              Blogs
            </h4>
          </div>
          <div className="headerDrawerCOntainer-Div2">
            <a
              target="_blank"
              href="https://www.facebook.com/HekaHealthyYou?mibextid=ZbWKwL"
            >
              <img src={facebook} />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/heka-healthy-you/"
            >
              <img src={linkedIn} />
            </a>
            <a target="_blank" href="mailto:support@hekahealth.in">
              <img src={mail} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/@hekahealthyyouprivatelimited/featured"
            >
              <img src={youtube} />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/heka__health?igsh=aXdhaTdlencyNnc="
            >
              <img src={insta} />
            </a>
            <a
              target="_blank"
              href="https://x.com/hekahealthyyou?t=bIVd2LIDtxooXT_znSYiMA&s=08"
            >
              <img src={twitter} />
            </a>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Fragment>
        <Drawer
          elevation={3}
          PaperProps={{
            sx: {
              width: "100%",
              backgroundColor: "grey",
            },
          }}
          anchor={"left"}
          open={state.left}
          onClose={toggleDrawer}
          ModalProps={{
            BackdropProps: {
              className: "drawerBackdrop",
            },
          }}
          className={state.left ? "drawerOpen" : "drawerClosed"}
        >
          {list()}
        </Drawer>
      </Fragment>
    </>
  );
};

export default HeaderDrawer;
