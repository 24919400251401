import React, { useState } from "react";
import img2 from "../../assets/careGiverImages/caregiver bg.jpg";
import "./CareGiverServicePage.css";
import whyChooseUsImg from "../../assets/careGiverImages/Rectangle 2586.svg";
import stps from "../../assets/physiotherapyImages/how instant services woek.svg";
import img1 from "../../assets/careGiverImages/Rectangle 2609.svg";
import bg1 from "../../assets/careGiverImages/caregiver Bg.svg";
import bg2 from "../../assets/careGiverImages/image 100.svg";
import bg3 from "../../assets/careGiverImages/bg.png";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dadi from "../../assets/homePageImages/Vector.svg";
import whiteImg1 from "../../assets/physiotherapyImages/mobile/Rectangle 2586.svg";
import mobileImgMain from "../../assets/careGiverImages/Frame 427319567.jpg";
import checkboxImg from "./Frame.png";
const CareGiverServicePage = (props) => {
  const { isMobile, arr1, bgArr, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Caregiver At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Empathetic experts offer tailored aid, empowering <br />{" "}
              independence and nurturing well- being through <br /> personalised
              support and care.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Caregiver At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Empathetic experts offer tailored aid, empowering independence and
              nurturing well- <br />
              being through personalised support and care.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
        {!isMobile && (
          <img
            style={{ top: "70%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>
      <img
        src={stps}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2.5rem auto" }
            : { width: "70%", display: "block", margin: " 3rem auto" }
        }
      />

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Types of Caregivers</h4>
        {!isMobile && (
          <img className="homePageContainer4-headMain-dadi5" src={dadi} />
        )}
      </div>
      <div className="careGiverPageContainer2">
        {isMobile ? (
          <img alt="images" src={bg2} style={{ width: "100%" }} />
        ) : (
          <img alt="images" src={img1} />
        )}
        <div className="careGiverPageContainer2-div">
          {bgArr.map((item) => {
            return (
              <div className="careGiverPageContainer2-div1">
                {isMobile ? (
                  <img
                    alt="images"
                    src={item.bgImage1}
                    style={{ height: "100%", width: "100%" }}
                  />
                ) : (
                  <img
                    alt="images"
                    src={item.bgImage}
                    // style={{ height: "100%", width: "100%" }}
                  />
                )}

                <div className="careGiverPageContainer2-div2">
                  <h4 className="careGiverPageContainer2-div-h">{item.head}</h4>
                  <div className="careGiverPageContainer2-div2-div">
                    {item.textArr.map((it) => {
                      return (
                        <div className="careGiverPageContainer2-aligncheckbox">
                          
                          <img
                            src={checkboxImg}
                            style={
                              isMobile
                                ? {
                                    width: "16px",
                                    height: "16px",
                                    borderRadius: "100%",
                                  }
                                : {
                                    width: "28px",
                                    height: "28px",
                                    borderRadius: "100%",
                                  }
                            }
                          />
                          <p className="careGiverPageContainer2-div-t2">{it}</p>
                        </div>
                      );
                    })}
                  </div>

                  <div className="careGiverPageContainer2-btn">
                    <button className="careGiverPageContainer2-btn1">
                      Starting at INR {item.price}/-
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Why hire Caregiver from us?
        </h4>

        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      <div className="careGiverPageContainer3">
        {isMobile ? (
          <img
            alt="images"
            src={bg3}
            className="elderlyCarePageContainer3-img1"
          />
        ) : (
          <img
            alt="images"
            src={bg1}
            className="elderlyCarePageContainer3-img1"
          />
        )}
        {arr1.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              {isMobile ? (
                <img
                  src={item.arrMobImg}
                  className="careGiverPageContainer3-cardImgabcd"
                />
              ) : (
                <img
                  src={item.arrImg}
                  className="careGiverPageContainer3-cardImg"
                />
              )}

              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={whyChooseUsImg}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}
                <h5
                  className={
                    isMobile
                      ? "careGiverPageContainer3-cardHeadc"
                      : "careGiverPageContainer3-cardHead"
                  }
                  style={isMobile ? { padding: "0.7rem 1rem 0.3rem 1rem" } : {}}
                >
                  {item.head}
                </h5>
                <p
                  style={isMobile ? { top: "46%" } : {}}
                  className="careGiverPageContainer3-cardText2"
                >
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CareGiverServicePage;
