import React, { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import LabTestComponent from "../../components/LabTestComponent/LabTestComponent";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img1 from "../../assets/labtestImages/Frame 427319446.svg";
import img2 from "../../assets/labtestImages/Frame 427319447.svg";
import img5 from "../../assets/labtestImages/Frame 427319448.svg";
import img6 from "../../assets/labtestImages/Frame 427319449.svg";
import labTestImg1 from "../../assets/labtestImages/Ellipse 498.svg";
import labTestImg2 from "../../assets/labtestImages/Ellipse 497.svg";
import labTestImg3 from "../../assets/labtestImages/Ellipse 493.svg";
import labTestImg4 from "../../assets/labtestImages/Ellipse 499.svg";

import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";

const LabTestContainer = () => {
  const { state } = useContext(Context);
  const pageIndex = "9";

  const arr = [
    {
      arrImg: labTestImg3,
      head: "Mini Check-Up",
      text: "BSF/BSR, Total Cholesterol, SGOT, SGPT, AST/ALT Ratio, TSH",
      price: "499/-",
    },
    {
      arrImg: labTestImg1,
      head: "Screen Check-Up",
      text: "HB, PCV, TLC, RBC, MCV, MPV, MCH, MCHC, RDW, Platelet",
      price: "899/-",
    },
    {
      arrImg: labTestImg2,
      head: "Active Check-Up",
      text: "GGT, Urea, BUN, Creatinine, BUN/Creatinine Ratio",
      price: "1,449/-",
    },
    {
      arrImg: labTestImg3,
      head: "Diabetes Active",
      text: "BSF, HBA1C, Lipid Screen, KFT Screen, Liver Function Test",
      price: "1,599/-",
    },

    {
      arrImg: labTestImg3,
      head: "Total Check-Up",
      text: "GGT, Calcium, Phosphorus, Electrolytes, VitaminD 25 -Hydroxy, T3, T4",
      price: "1,949/-",
    },

    {
      arrImg: labTestImg1,
      head: "Executive Check-up",
      text: "Lipid Profile, Liver Function Test, Kidney Function Test",
      price: "3,199/-",
    },
    {
      arrImg: labTestImg2,
      head: "Advance Check-Up",
      text: "Total Cholesterol, HDL, VLDL, LDL Direct, Triglycerides",
      price: "3,999/-",
    },
    {
      arrImg: labTestImg4,
      head: "Platinum Check-Up",
      text: "Total Cholesterol, HDL, VLDL, LDL Direct, Triglycerides",
      price: "4,999/-",
    },
  ];

  const arr1 = [
    {
      arrImg: img1,
      head: "Expert Guidance",
      text: "Personalised advice from experienced professionals for your health journey.",
    },
    {
      arrImg: img2,
      head: "Comfortable Experience",
      text: "Accurate testing from home for utmost convenience.",
    },
    {
      arrImg: img1,
      head: "Timely Results",
      text: "Reliable results empowering informed health decisions.",
    },
    {
      arrImg: img1,
      head: "Confidentiality Assured",
      text: "Priority on handling data with utmost confidentiality.",
    },
    {
      arrImg: img5,
      head: "Comprehensive Testing",
      text: "Wide range of tests for thorough understanding of well-being.",
    },
    {
      arrImg: img6,
      head: "Customer-Centric",
      text: "Personalised care and support for your health journey.",
    },
  ];

  const dropDownArr = [
    {
      label: "How can I book lab tests from HHY?",
      text: "To book lab tests with HHY, reach out via our enquiry form or call us at 8010091010. We're here to assist you promptly.",
    },
    {
      label: "Is a doctor's prescription necessary?",
      text: "While not mandatory, consulting a doctor for a few routine tests ensures the best guidance for your health journey. Your well-being is our priority. In case of pregnancy, a valid prescription is a must to undergo any examination.",
    },
    {
      label: "How will my sample be collected?",
      text: "A trained phlebotomist will collect your venous blood sample with care at your location, ensuring proper handling for accurate assessment.",
    },
    {
      label: "Can I cancel or reschedule my appointment?",
      text: "You can cancel your appointment with us. Please note our refund policy: 48 hours prior - full refund, 24 to 48 hours - half refund, less than 24 hours - no refund. We understand plans change.",
    },
    {
      label: "Is fasting necessary before tests?",
      text: "Fasting requirements vary for different tests. Contact our team for specific instructions tailored to your needs. We're here to make the process easier for you.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <LabTestComponent
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default LabTestContainer;
