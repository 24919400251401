import React from "react";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import Footer from "../../shared/components/Footer/Footer";
import ReactPlayer from "react-player";
import RoomIcon from "@mui/icons-material/Room";

function Card(props) {
  return (
    <div
      className={`w-1/2 mx-auto text-center ${
        props.heading == "Upcoming Events"
          ? "border-r-2 border-solid border-black"
          : ""
      } `}
    >
      <h3>{props.heading}</h3>
      {props.data.map((item, index) => (
        <div key={index} className="flex items-center shadow-md w-[95%] min-h-[120px] mx-auto my-8 rounded-md bg-[#f3f3f375]">
          <div className=" bg-[#3F6B68] w-[30%] text-white flex flex-col justify-center h-[clamp(120px,120px,200px)]">
            <p className="text-2xl mt-1 mb-0">{item.startDate}</p>
            <p className="text-2xl mt-1 mb-0">{item.startTime}</p>
          </div>
          <div className="flex flex-col items-center justify-center flex-1">
            <h4>{item.name}</h4>
            <p className=" mb-2">{item.description}</p>
            <p className="text-[#5a5a5a] mb-0">
              <RoomIcon /> {item.location}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
function Events(props) {
  const { eventsData } = props;
  console.log(eventsData);
  const [newHeight, setNewHeight] = React.useState();
  React.useEffect(() => {
    const galleryImage = document.getElementById("eventsVideo");
    if (galleryImage) {
      const width = galleryImage.offsetWidth;
      let newHeight;
      if (width >= 768) {
        newHeight = Math.round((width - width * 0.3) * (9 / 16));
      } else newHeight = Math.round(width * (9 / 16));
      setNewHeight(newHeight);
    }
  }, []);
  return (
    <div>
      <HeaderContainer />
      <div className="container video-container">
        <div className="row cst-video">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="bls-video-sec">
              <div className="video-image">
                <ReactPlayer
                  id="eventsVideo"
                  url={
                    "https://zylicontechnologies.com/hekaAPI/uploads/events/Heka%20Healthy%20You%20(BLS%20Training).mp4"
                  }
                  stopOnUnmount
                  playing={true}
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload noplaybackrate",
                        disablePictureInPicture: true,
                      },
                    },
                  }}
                  playIcon={false}
                  pip={false}
                  muted={true}
                  width={null}
                  height={`${newHeight}px`}
                  className={`transition_class [&>video]:rounded-2xl my-10 !h-[${newHeight}px]`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex my-20">
        <Card data={eventsData.upcomingEvents} heading={"Upcoming Events"} />
        <Card data={eventsData.pastEvents} heading={"Past Events"} />
      </div>
      <Footer />
    </div>
  );
}

export default Events;
