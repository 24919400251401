import React from "react";
import img2 from "../../assets/pregnancyCareImgages/pregnancy care.jpeg";
import "./PregnancyCareServicePage.css";
import bgImg from "../../assets/pregnancyCareImgages/pregnancyBg.svg";
import bgImg1 from "../../assets/pregnancyCareImgages/bg.png";
import whyChooseUsImg from "../../assets/homePageImages/whyChooseUs/Why choose us.svg";
import whyChooseUsImg1 from "../../assets/homePageImages/why choose us final mobile.svg";
import mobileImgMain from "../../assets/pregnancyCareImgages/Frame 427319564.jpg";
const PregnancyCareServicePage = (props) => {
  const { arr, isMobile, handleEnquireNow } = props;

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Pregnancy Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              Comprehensive pregnancy care supports maternal and fetal health
              with medical and <br /> emotional support throughout.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Pregnancy Care
            </h1>
            <p className="consultationPageContainer1Content-text">
              Comprehensive pregnancy care supports maternal and fetal health
              with medical and <br /> emotional support throughout.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <img
        src={isMobile ? whyChooseUsImg1 : whyChooseUsImg}
        style={{ display: "block", margin: "auto" }}
      />

      <h4 className="homePageContainer4-headMain">Our Services</h4>

      <div className="pregnancyCarePageContainer3">
        <img
          src={isMobile ? bgImg1 : bgImg}
          style={{ position: "absolute", zIndex: 0, left: 0 }}
        />

        {arr.map((item, index) => {
          const style1 = index === 0 ? { padding: "0rem 4rem" } : {};
          const style2 = index === 0 ? { padding: "0rem 1rem" } : {};

          const style3 = index === 0 ? { paddingTop: "6rem" } : {};
          const style4 = index === 0 ? { paddingTop: "2.4rem" } : {};
          const style5 = index === 5 ? { top: "50%" } : {};
          const style6 = index === 5 ? { top: "26%" } : {};
          return (
            <div key={index} className="pregnancyCarePageContainer3-card">
              <div
                style={isMobile ? style4 : style3}
                className="pregnancyCarePageContainer3-cardDiv1"
              >
                <img
                  src={item.arrImg}
                  className="pregnancyCarePageContainer3-cardImg"
                />
                <h5
                  style={isMobile ? style2 : style1}
                  className="pregnancyCarePageContainer3-cardHead"
                >
                  {item.head}
                </h5>
              </div>

              <div className="pregnancyCarePageContainer3-cardDiv2">
                <img src={item.bgImage} style={{ width: "100%" }} />
                <p
                  style={isMobile ? style6 : style5}
                  className="pregnancyCarePageContainer3-cardText1"
                >
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PregnancyCareServicePage;
