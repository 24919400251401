import React, { useContext, useState, useEffect } from "react";
import ElderlyCareServicePage from "../../components/ElderlyCareServicePage/ElderlyCareServicePage";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import dayjs from "dayjs";
import img1 from "../../assets/pregnancyCareImgages/SVGRepo_iconCarrier.svg";
import img2 from "../../assets/pregnancyCareImgages/Frame.svg";
import img3 from "../../assets/pregnancyCareImgages/Frame (1).svg";
import img4 from "../../assets/pregnancyCareImgages/Layer_1.svg";
import img5 from "../../assets/pregnancyCareImgages/Capa_1.svg";
import img6 from "../../assets/pregnancyCareImgages/Frame (2).svg";
import PregnancyCareServicePage from "../../components/PregnancyCareServicePage/PregnancyCareServicePage";
import bg1 from "../../assets/pregnancyCareImgages/Frame 427319246.svg";
import bg2 from "../../assets/pregnancyCareImgages/Frame 427319247.svg";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";

const PregnancyServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "4";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      bgImage: bg1,
      head: "Screening Tests and Ultrasound",
      text: "Screening tests and ultrasounds to detect any potential complications early on.",
    },
    {
      bgImage: bg2,
      arrImg: img2,
      head: "Regular Prenatal Checkups",
      text: "Regular prenatal checkups with healthcare providers to monitor the health of both mother and baby.",
    },

    {
      bgImage: bg1,
      arrImg: img3,
      head: "Nutritional Guidance",
      text: "Nutritional guidance to ensure the mother and baby receive adequate nutrients for optimal growth and development.",
    },
    {
      bgImage: bg2,
      arrImg: img4,
      head: "Emotional Support",
      text: "Emotional support and counseling for addressing fears, anxieties, or mood changes during pregnancy. ",
    },
    {
      bgImage: bg1,
      arrImg: img5,
      head: "Postnatal care",
      text: " Postnatal care encompasses comprehensive support for both mother and baby following childbirth. ",
    },
    {
      bgImage: bg2,
      arrImg: img6,
      head: "Education on childbirth and newborn care",
      text: "Education on childbirth preparation, breastfeeding, and newborn care.",
    },
  ];

  const dropDownArr = [
    {
      label: "What does pregnancy care include?",
      text: "It encompasses prenatal checkups, screenings, emotional support, nutrition guidance, and comprehensive postnatal care services.",
    },
    {
      label: "When should I start pregnancy care?",
      text: "It's advisable to begin as soon as pregnancy is confirmed to ensure optimal maternal and foetal health.",
    },
    {
      label: "How often are prenatal checkups?",
      text: "Regular checkups are scheduled, usually monthly in early pregnancy and more frequent in later stages.",
    },
    {
      label: "What happens during a prenatal checkup?",
      text: "Healthcare providers monitor the mother's health, check foetal development, and address any concerns or questions.",
    },
    {
      label: "Is emotional support provided during pregnancy care?",
      text: "Yes, trained professionals offer counselling and support to address emotional needs, fears, and anxieties throughout the pregnancy journey.",
    },
    {
      label: "Do you offer postnatal care?",
      text: "Yes, comprehensive support including medical checkups, lactation support, and newborn care guidance is provided after childbirth.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <PregnancyCareServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <FaqComponent isMobile={selectIsMobile(state)} arr={dropDownArr} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />

      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default PregnancyServiceContainer;
