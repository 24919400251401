import React, { useState, useContext, useEffect } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import img1 from "../../assets/pregnancyCareImgages/SVGRepo_iconCarrier.svg";
import img2 from "../../assets/pregnancyCareImgages/Frame.svg";
import img3 from "../../assets/pregnancyCareImgages/Frame (1).svg";
import img4 from "../../assets/pregnancyCareImgages/Layer_1.svg";
import img5 from "../../assets/pregnancyCareImgages/Capa_1.svg";
import img6 from "../../assets/pregnancyCareImgages/Frame (2).svg";
import bg1 from "../../assets/pregnancyCareImgages/Frame 427319246.svg";
import bg2 from "../../assets/pregnancyCareImgages/Frame 427319247.svg";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import GeneralWellnessServicePage from "../../components/GeneralWellnessServicePage/GeneralWellnessServicePage";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";

const GeneralWellnessServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "5";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      bgImage: bg1,
      head: "Screening Tests and Ultrasound",
      text: "Screening tests and ultrasounds to detect any potential complications early on.",
    },
    {
      bgImage: bg2,
      arrImg: img2,
      head: "Regular Prenatal Checkups",
      text: "Regular prenatal checkups with healthcare providers to monitor the health of both mother and baby.",
    },

    {
      bgImage: bg1,
      arrImg: img3,
      head: "Nutritional Guidance",
      text: "Nutritional guidance to ensure the mother and baby receive adequate nutrients for optimal growth and development.",
    },
    {
      bgImage: bg2,
      arrImg: img4,
      head: "Emotional Support",
      text: "Emotional support and counseling for addressing fears, anxieties, or mood changes during pregnancy. ",
    },
    {
      bgImage: bg1,
      arrImg: img5,
      head: "Postnatal care",
      text: " Postnatal care encompasses comprehensive support for both mother and baby following childbirth. ",
    },
    {
      bgImage: bg2,
      arrImg: img6,
      head: "Education on childbirth and newborn care",
      text: "Education on childbirth preparation, breastfeeding, and newborn care.",
    },
  ];

  const dropDownArr = [
    {
      label: "What is general wellness?",
      text: "It's a holistic approach to health, covering physical, mental, and emotional well-being through healthy practices and preventive care.",
    },
    {
      label: "Why choose our wellness services?",
      text: "We offer compassionate guidance tailored to individual needs and empower individuals to drive their own health journey.",
    },
    {
      label: "What preventive measures are included?",
      text: "Regular check-ups, screenings, vaccinations, and other measures to detect and prevent illnesses early.",
    },
    {
      label: "How do you address mental health?",
      text: "We emphasise stress management, mindfulness, and therapy when necessary to promote mental well-being.",
    },
    {
      label: "Who can benefit from general wellness services",
      text: "Individuals of all ages and backgrounds committed to prioritising their health and well-being can avail these services and choose for custom plans.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <GeneralWellnessServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default GeneralWellnessServiceContainer;
