import React from "react";
import hh_logo from "./Frame 427320019.svg";
import "./Header.css";
import icon from "../../../assets/SVGRepo_iconCarrier.svg";
import icon1 from "../../../assets/Vector.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaXTwitter } from "react-icons/fa6";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import facebook from "../../../assets/headerIcons/fb.svg";
import linkedIn from "../../../assets/headerIcons/linekdin.svg";
import mail from "../../../assets/headerIcons/mail.svg";
import twitter from "../../../assets/headerIcons/twitter 1.svg";
import youtube from "../../../assets/headerIcons/Layer_1.svg";
import insta from "../../../assets/headerIcons/Frame.svg";
const Header = (props) => {
  const {
    isHeaderVisible,
    handleHomePage,
    showCities,
    cityArr,
    cityRef,
    handleShowCity,
    showServices,
    setShowServices,
    services,
    handleAboutUs,
    handleServicesOption,
    handlePhysioTherapy,
    handleCareService,
    handleMedicalEquipment,
    handleConsultation,
    handleLabTest,
    handleBlogs,
  } = props;
  return (
    <>
      <div className={isHeaderVisible ? "headerContainer" : "headerContainerA"}>
        <img
          src={hh_logo}
          alt="logo"
          // className="headerLogo"
          onClick={handleHomePage}
        />

        <div className="headerLabels">
          <p
            onMouseEnter={() => setShowServices(false)}
            onClick={handleHomePage}
            className="headerLabel"
          >
            Home
          </p>
          <p
            onMouseEnter={() => setShowServices(false)}
            onClick={handleAboutUs}
            className="headerLabel"
          >
            About Us
          </p>
          <p onMouseEnter={() => setShowServices(true)} className="headerLabel">
            Our Services
          </p>
          <p
            onMouseEnter={() => setShowServices(false)}
            className="headerLabel"
            onClick={() => handleBlogs()}
          >
            Blogs
          </p>
          {/* <p className="headerLabel">Careers</p>
          <p className="headerLabel">Events</p> */}
          {showServices ? (
            <>
              <div
                onMouseLeave={() => setShowServices(false)}
                className="header-serviceDialog"
              >
                {services.map((item, index) => {
                  return (
                    <div
                      onClick={() => handleServicesOption(index)}
                      className="header-alignCity"
                    >
                      <div>{item}</div>
                      <img
                        src={icon1}
                        style={{ height: "0.5rem", width: "0.5rem" }}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {/* <div style={{ display: "flex", gap: "0.5rem" }}> */}
        <div style={{ display: "flex", gap: "1rem" }}>
          {/* <div className="headerContainerIcons">
            <YouTubeIcon fontSize="small" htmlColor="#fff" />
          </div>
          <div className="headerContainerIcons">
            <FacebookIcon fontSize="small" htmlColor="#fff" />
          </div>
          <div className="headerContainerIcons">
            <LinkedInIcon fontSize="small" htmlColor="#fff" />
          </div>
          <div className="headerContainerIcons">
            <InstagramIcon fontSize="small" htmlColor="#fff" />
          </div>
          <div
            style={{
              padding: "0px 8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="headerContainerIcons"
          >
            <FaXTwitter fontSize="small" color="#fff" />
          </div> */}
          <a
            target="_blank"
            href="https://www.facebook.com/HekaHealthyYou?mibextid=ZbWKwL"
          >
            <img src={facebook} />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/heka-healthy-you/"
          >
            <img src={linkedIn} />
          </a>
          <a target="_blank" href="mailto:support@hekahealth.in">
            <img src={mail} />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/@hekahealthyyouprivatelimited/featured"
          >
            <img src={youtube} />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/heka__health?igsh=aXdhaTdlencyNnc="
          >
            <img src={insta} />
          </a>
          <a
            target="_blank"
            href="https://x.com/hekahealthyyou?t=bIVd2LIDtxooXT_znSYiMA&s=08"
          >
            <img src={twitter} />
          </a>
        </div>

        {/* <div onClick={handleShowCity} className="headerBtn">
          <div>Choose your city</div>
          <ArrowDropDownIcon sx={{ fontSize: "14px" }} />
        </div> */}
        {showCities ? (
          <>
            <div ref={cityRef} className="header-cityDialog">
              {cityArr.map((item) => {
                return (
                  <div className="header-alignCity">
                    <div>{item}</div>
                    <img src={icon} style={{ height: "1rem", width: "1rem" }} />
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div
        onMouseEnter={() => setShowServices(false)}
        className={!isHeaderVisible ? "optionHeaderA" : "optionHeader"}
      >
        <div className="optionHeaderDiv">
          <p onClick={handlePhysioTherapy} className="optionHeaderLabel">
            Physiotherapy at Home
          </p>
          <p onClick={handleCareService} className="optionHeaderLabel">
            Caregiver at Home
          </p>
          <p onClick={handleMedicalEquipment} className="optionHeaderLabel">
            Medical Equipment at Home
          </p>
          <p onClick={handleConsultation} className="optionHeaderLabel">
            Consultation at Home
          </p>
          <p onClick={handleLabTest} className="optionHeaderLabel">
            Lab Test at Home
          </p>
        </div>
        <p className="optionHeaderLabel">Call us at: 801-0711-010</p>
      </div>
    </>
  );
};

export default Header;
