import React, { createRef } from "react";
import "./ConsultationComponent.css";
import img from "../../assets/consultationImages/personalized consultations bg.jpg";
import img1 from "../../assets/consultationImages/how instant services woek.svg";
import bg1 from "../../assets/consultationImages/Rectangle 2586.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import bg2 from "../../assets/consultationImages/consultBg.svg";
import mobileImgMain from "../../assets/consultationImages/Frame 427319570.jpg";
import whiteImg1 from "../../assets/physiotherapyImages/mobile/Rectangle 2586.svg";
import bg from "../../assets/physiotherapyImages/bg.png";
import dadi from "../../assets/homePageImages/Vector.svg";
const ConsultationComponent = (props) => {
  const {
    isMobile,
    arr,
    serviceCardsData,
    setting,
    currentSlide,
    serviceCardsData1,
    setting1,
    handleEnquireNow,
  } = props;
  const mySlider = createRef();
  const mySlider1 = createRef();

  const alignImages = () => {
    return serviceCardsData.map((item, index) => {
      //   const isCenter = index === currentSlide;
      return (
        <>
          <div
            key={index}
            // className={
            //   isCenter ? "consultationPageImgDiv1A" : "consultationPageImgDiv1U"
            // }
            className="consultationPageImgDiv1A"
            // style={
            //   isMobile
            //     ? { width: (window.innerWidth * 6.5) / 10 }
            //     : { width: "auto" }
            // }
          >
            <div className={"consultationPageText1Div"}></div>
            <img
              key={index}
              src={item.images}
              alt="images"
              className=""
              style={{
                borderRadius: "16px",
                width: "80%",
                display: "block",
                margin: "auto",
              }}
            />

            <h4 className="consultationPageHead1">{item.head}</h4>
            <p className="consultationPageText1">{item.text}</p>
          </div>
        </>
      );
    });
  };

  const alignImages1 = () => {
    return serviceCardsData1.map((item, index) => {
      const isCenter = index === currentSlide;
      return (
        <>
          <div
            key={index}
            // style={
            //   isMobile
            //     ? { width: (window.innerWidth * 8) / 10 }
            //     : { width: "auto" }
            // }
            className={
              isCenter ? "consultationPageImgDiv1A" : "consultationPageImgDiv1U"
            }

            // className="consultationPageImgDiv1A"
          >
            <img
              key={index}
              src={item.images}
              alt="images"
              className=""
              style={{
                width: "70%",
                display: "block",
                margin: "auto",
                paddingBottom: "2px 4rem ",
                borderBottom: "2px solid #bcbcbc",
              }}
            />

            <h4
              className={
                isCenter ? "consultationPageHead2" : "consultationPageHead1"
              }
            >
              {item.drName}
            </h4>
            <p
              className={
                isCenter ? "consultationPageText2" : "consultationPageText1"
              }
            >
              {item.designation}, {item.education}
            </p>
            <p
              className={
                isCenter ? "consultationPageText2" : "consultationPageText1"
              }
              style={{ padding: "1rem 0rem" }}
            >
              {item.text}
            </p>
          </div>
          <div className={isCenter && "consultationPageText1DivAB"}></div>
        </>
      );
    });
  };

  const handleNext1 = () => {
    mySlider.current.slickNext();
  };

  const handlePrev1 = () => {
    mySlider.current.slickPrev();
  };

  const handleNext2 = () => {
    mySlider1.current.slickNext();
  };

  const handlePrev2 = () => {
    mySlider1.current.slickPrev();
  };
  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1
              style={{ textAlign: "center" }}
              className="consultationPageContainer1Content-Head"
            >
              Personalized Consultations At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Access expert medical advice conveniently, ensuring comprehensive
              care and comfort <br /> in familiar surroundings.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Personalized Consultations At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Access expert medical advice conveniently, ensuring comprehensive
              care and comfort <br /> in familiar surroundings.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
        {isMobile ? <></> : <img className="consultationDadi1" src={dadi} />}
      </div>

      <img
        src={img1}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2.5rem auto" }
            : { width: "70%", display: "block", margin: " 3rem auto" }
        }
      />

      {/* <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Our Doctors</h4>
        {isMobile ? <></> : <img className="consultationDadi2" src={dadi} />}
      </div> */}

      {/* <div className="consultationPageContainer21">
        <img
          alt="images"
          src={bg2}
          className="elderlyCarePageContainer3-img1"
        />
        <Slider ref={mySlider1} arrows={false} {...setting1}>
          {alignImages1()}
        </Slider>

        {!isMobile && (
          <>
            <div className="homePageContainer2-arrow1">
              <ArrowRightAltIcon onClick={handlePrev2} />
            </div>
            <div className="homePageContainer2-arrow2">
              <ArrowRightAltIcon onClick={handleNext2} />
            </div>
          </>
        )}
      </div> */}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain"> Why Consult with us?</h4>
        {isMobile ? <></> : <img className="consultationDadi3" src={dadi} />}
      </div>

      <div className="consultationPageContainer3">
        {isMobile ? (
          <img
            alt="images"
            src={bg}
            className="elderlyCarePageContainer3-img1"
          />
        ) : (
          <img
            alt="images"
            src={bg2}
            className="elderlyCarePageContainer3-img1"
          />
        )}
        {arr.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img
                src={item.arrImg}
                className="consultationPageContainer3-Img"
              />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={bg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}
                <h5 className="careGiverPageContainer3-cardHead">
                  {item.head}
                </h5>
                <p
                  style={isMobile ? { top: "60%" } : {}}
                  className="careGiverPageContainer3-cardText2"
                >
                  {item.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Our Specialities</h4>
        {isMobile ? <></> : <img className="consultationDadi4" src={dadi} />}
      </div>

      <div className="consultationPageContainer2">
        <img
          alt="images"
          src={bg2}
          className="elderlyCarePageContainer3-img1"
        />
        <Slider ref={mySlider} arrows={false} {...setting}>
          {alignImages()}
        </Slider>
        {!isMobile && (
          <>
            <div style={{ left: "48px" }} className="homePageContainer2-arrow1">
              <ArrowRightAltIcon onClick={handlePrev1} />
            </div>
            <div
              style={{ right: "48px" }}
              className="homePageContainer2-arrow2"
            >
              <ArrowRightAltIcon onClick={handleNext1} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConsultationComponent;
