import React, { createRef } from "react";
import img2 from "../../assets/medicalImages/medical equipment bg.jpg";
import "./MedicalEquipmentPage.css";
import stps from "../../assets/physiotherapyImages/how instant services woek.svg";
import bg from "../../assets/medicalImages/Rectangle 2586.svg";
import bg1 from "../../assets/medicalImages/mdBg.svg";
import dadi from "../../assets/homePageImages/Vector.svg";
import mobileImgMain from "../../assets/medicalImages/Frame 427319568.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const MedicalEquipmentPage = (props) => {
  const { isMobile, arr, arr1, handleEnquireNow } = props;
  const mySlider = createRef();

  const setting = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Medical Equipment At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Our arsenal comprises vital instruments, from <br />
              stethoscopes to advanced imaging tech, facilitating <br />{" "}
              diagnosis, treatment, and care.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img2} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Medical Equipment At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Our arsenal comprises vital instruments, from stethoscopes to
              advanced imaging tech, <br /> facilitating diagnosis, treatment,
              and care.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      <img
        src={stps}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2.5rem auto" }
            : { width: "70%", display: "block", margin: " 3rem auto" }
        }
      />

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Types of Medical Equipment
        </h4>
        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%", left: "66%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      {isMobile ? (
        // <Slider ref={mySlider} {...setting}>
        //   {arr.map((item, index) => {
        //     return (
        //       <div key={index} className="mequipPageContainer3-card">
        //         <img src={item.arrImg} style={{ width: "100%" }} />
        //         <div>
        //           <h4 className="mequipPageContainer3-cardHead">{item.head}</h4>
        //           <p className="mequipPageContainer3-cardText">{item.text}</p>
        //         </div>
        //       </div>
        //     );
        //   })}
        // </Slider>
        <></>
      ) : (
        <div className="mequipPageContainer3">
          <img
            alt="images"
            src={bg1}
            className="elderlyCarePageContainer3-img1"
          />
          {arr.map((item, index) => {
            return (
              <div key={index} className="mequipPageContainer3-card">
                <img src={item.arrImg} style={{ width: "100%" }} />
                <div>
                  <h4 className="mequipPageContainer3-cardHead">{item.head}</h4>
                  <p className="mequipPageContainer3-cardText">{item.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          Why take Medical Equipment from us?
        </h4>
        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%" }}
            className="homePageContainer4-headMain-dadi6"
            src={dadi}
          />
        )}
      </div>

      <div className="mequipPageContainer4">
        <img
          alt="images"
          src={bg1}
          className="elderlyCarePageContainer3-img1"
        />
        {arr1.map((item, index) => {
          return (
            <div className="mequipPageContainer4-card">
              <img
                src={item.arrImg}
                className="mequipPageContainer4-cardImg1"
              />
              <div className="mequipPageContainer4-cardDiv">
                <img src={bg} style={{ width: "100%" }} />
                <div className="mequipPageContainer4-cardData">
                  <h4 className="mequipPageContainer4-cardHead">{item.head}</h4>
                  <p className="mequipPageContainer4-cardText">{item.text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MedicalEquipmentPage;
