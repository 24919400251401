import { useState } from "react";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import {
  ABOUT_US_PAGE,
  BLOG_PAGE,
  ELDERLY_CARE_SERVICE_PAGE,
  GENERAL_WELLNESS_SERVICE_PAGE,
  HOME_PAGE,
  OPERATIVE_CARE_SERVICE_PAGE,
  PHYSIO_THERAPY_SERVICE_PAGE,
  PREGNANCY_CARE_SERVICE_PAGE,
  PRIVACY_POLICY,
  SAFETY_TRAINING_SERVICE_PAGE,
} from "../../../routes/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { POLICIES } from "../FooterContainer/Footer.constants";
import { APIPostRequest } from "../../../config/HttpClient";

const MobileFooterContainer = () => {
  const history = useHistory();
  const [showDialog, setDialog] = useState(false);
  const [subscribeInput, setSubscribeInput] = useState({
    value: "",
    error: "",
  });

  const quickLinksLabels = [
    {
      name: "About HHY",
      link: ABOUT_US_PAGE,
    },
    {
      name: "Careers",
      link: HOME_PAGE,
    },
    {
      name: "Blogs",
      link: BLOG_PAGE,
    },
    {
      name: "Events",
      link: HOME_PAGE,
    },
  ];

  const ourServicesLabels = [
    {
      name: "Safety Trainings",
      link: SAFETY_TRAINING_SERVICE_PAGE,
    },
    {
      name: "Elderly Care",
      link: ELDERLY_CARE_SERVICE_PAGE,
    },
    {
      name: "Pregnancy Care",
      link: PREGNANCY_CARE_SERVICE_PAGE,
    },
    {
      name: "Operative Care",
      link: OPERATIVE_CARE_SERVICE_PAGE,
    },
    // {
    //   name: "General Wellness",
    //   link: GENERAL_WELLNESS_SERVICE_PAGE,
    // },
  ];

  const instantLabels = [
    {
      name: "Physiotherapy",
      link: PHYSIO_THERAPY_SERVICE_PAGE,
    },
    {
      name: "Care giver",
      link: ELDERLY_CARE_SERVICE_PAGE,
    },
    {
      name: "Consultant",
      link: PREGNANCY_CARE_SERVICE_PAGE,
    },
    {
      name: "Lab test",
      link: OPERATIVE_CARE_SERVICE_PAGE,
    },
    {
      name: "Equipment",
      link: GENERAL_WELLNESS_SERVICE_PAGE,
    },
  ];

  const handleClick = (pageURL) => {
    history.push(pageURL);
  };

  const handleDialog = () => {
    setDialog(!showDialog);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    let error = "";

    if (!value) {
      error = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      error = "Invalid email address";
    }

    setSubscribeInput({
      value: value,
      error: error,
    });
  };

  const handleSubscribe = () => {
    if (!subscribeInput.error) {
      setDialog(false);
    } else {
      setSubscribeInput({
        value: "",
        error: "Please Enter valid email-id",
      });
    }
  };

  const handlePolicy = (policyType) => {
    if (policyType == 1) {
      history.push(PRIVACY_POLICY);
    } else {
      var formData = new FormData();
      formData.append("data", `{"policyFlag":${policyType}}`);
      APIPostRequest(
        POLICIES,
        formData,
        (response) => {
          window.open(response?.policyLink, "_blank");
          console.log("Policy RESPONSE", response);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <>
      <MobileFooter
        quickLinksLabels={quickLinksLabels}
        ourServicesLabels={ourServicesLabels}
        instantLabels={instantLabels}
        handleClick={handleClick}
        showDialog={showDialog}
        handleDialog={handleDialog}
        handleChange={handleChange}
        subscribeInput={subscribeInput}
        handleSubscribe={handleSubscribe}
        handlePolicy={handlePolicy}
      />
    </>
  );
};

export default MobileFooterContainer;
