import React, { createRef, useEffect, useState } from "react";
import "./AboutUsPage.css";
import img from "../../assets/aboutUsPageImages/aboutMain.png";
import imgAb from "../../assets/aboutUsPageImages/aboutusBg.svg";
import whyChooseUsImg from "../../assets/homePageImages/whyChooseUs/Why choose us.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import bg from "../../assets/aboutUsPageImages/Rectangle 2514.png";
import bg1 from "../../assets/aboutUsPageImages/Rectangle 2511.png";
import bg2 from "./Rectangle 2576.png";
import bg2M from "./Rectangle 2514 (1).svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import user1 from "../../assets/aboutUsPageImages/Frame.png";
import users from "../../assets/aboutUsPageImages/Frame1.png";
import merger from "../../assets/aboutUsPageImages/merger.png";
import img1 from "./Rectangle 2494.png";
import img2 from "./Rectangle 2494 (1).png";
import img3 from "./Rectangle 2494 (2).png";
import img4 from "./Pregnancy care testimonial 1.png";
import img5 from "../../assets/aboutUsPageImages/safety training process.jpeg";
import mobileWhy from "./why choose us final mobile.svg";
import bgM from "./Rectangle 2514.svg";
import iconBg from "./Rectangle 2510.svg";
import bgBg from "./Rectangle 2511.svg";
import mobileImgMain from "../../assets/aboutUsPageImages/mobileAboutMain.png";
import abcdImg from "./Rectangle 2551.png";
const AboutUsPage = (props) => {
  const { isMobile } = props;
  const mySlider = createRef();
  const dropDownArr = [
    {
      label: "What is Webflow and why is it the best website builder?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      label: "What is your favorite template from BRIX Templates?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      label: "How do you clone a Webflow Template from the Showcase?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      label: "Why is BRIX Templates the best Webflow agency out there?",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleIndex = (i) => {
    setSelectedIndex(i);
  };

  const icons = [users, user1, user1, user1, user1, user1, user1];

  const data = [
    {
      img: users,
      name: "Raghav Kad",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user1,
      name: "Nishant Kakkar",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user1,
      name: "Pranav Miglani",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user1,
      name: "Kuldeep Singh",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user1,
      name: "Nishant Kakkar",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user1,
      name: "Pranav Miglani",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
    {
      img: user1,
      name: "Kuldeep Singh",
      designation: "Co Founder",
      experience: 2,
      location: "Gurgaon, Haryana",
      email: "abcd@hekahealthyyou.com",
      text: "hriowndn it is an amzing place for amazing care and specialities, care and specialities, i loved my timey time",
    },
  ];

  const alignData = () => {
    return userIndex.map((item, index) => {
      return (
        <>
          {index === 0 && (
            <div className="aboutUsPageContainer3-sliderCont">
              <img src={isMobile ? bgBg : bg1} />
              <div className="aboutUsPageContainer3-sliderDiv">
                {!isMobile && (
                  <div>
                    <h6 className="aboutUsPageContainer3-sliderDiv-h">
                      {item.name}
                    </h6>
                    <p className="aboutUsPageContainer3-sliderDiv-d">
                      {item.designation}
                    </p>
                  </div>
                )}
                <div>
                  <p className="aboutUsPageContainer3-sliderDiv-h1">
                    Years of Experience
                  </p>
                  <p className="aboutUsPageContainer3-sliderDiv-t">
                    {item.experience} years
                  </p>
                </div>
                <div>
                  <p className="aboutUsPageContainer3-sliderDiv-h1">Location</p>
                  <p className="aboutUsPageContainer3-sliderDiv-t">
                    {item.location}
                  </p>
                </div>
                <div>
                  <p className="aboutUsPageContainer3-sliderDiv-h1">
                    Email Address
                  </p>
                  <p className="aboutUsPageContainer3-sliderDiv-t">
                    {item.email}
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      );
    });
  };

  const [value, setValue] = useState(9.6);

  // useEffect(() => {
  //   if (currentSlide === 0) {
  //     setValue(12);
  //   } else if (currentSlide === 1) {
  //     setValue(6);
  //   } else if (currentSlide === 2) {
  //     setValue(0);
  //   } else if (currentSlide === 3) {
  //     setValue(-6);
  //   }
  // }, [currentSlide]);

  // useEffect(() => {
  //   const increment = currentSlide * 8;
  //   setValue(9.6 - increment);
  // }, [currentSlide]);

  const [iconIndex, setIconIndex] = useState(icons);
  const [userIndex, setUserIndex] = useState(data);

  const handleRightButtonClick = () => {
    const shiftediconIndex = [
      iconIndex[iconIndex.length - 1],
      ...iconIndex.slice(0, iconIndex.length - 1),
    ];
    setIconIndex(shiftediconIndex);
    handleRightUser();
  };

  const handleLeftButtonClick = () => {
    const shiftediconIndex = [...iconIndex.slice(1), iconIndex[0]];
    setIconIndex(shiftediconIndex);
    handleLeftUser();
  };

  const handleRightUser = () => {
    const shiftedData = [
      userIndex[userIndex.length - 1],
      ...userIndex.slice(0, userIndex.length - 1),
    ];
    setUserIndex(shiftedData);
  };

  const handleLeftUser = () => {
    const shiftedData = [...userIndex.slice(1), userIndex[0]];
    setUserIndex(shiftedData);
  };

  const alignIcons = () => {
    return iconIndex.map((item, index) => {
      return (
        <img
          src={item}
          alt="team"
          key={index}
          style={
            isMobile
              ? { height: "24px", width: "24px" }
              : { height: "96px", width: "96px" }
          }
        />
      );
    });
  };

  const alignIconsMobile = () => {
    return iconIndex.map((item, index) => {
      return (
        <div className="aboutUsPage-MobileIconsCOntainer">
          <img src={iconBg} />
        </div>
      );
    });
  };

  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Healthy You at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Welcome to Heka Healthy You! We don’t just care about you today,
              but also help shape your tomorrow towards a healthier, happier
              you.
            </p>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="aboutUsPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Healthy You at Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Empowering through prevention and cure. Enhancing awareness for a
              healthier <br /> future and supporting your journey to betterment.
            </p>
          </div>
        </div>
      )}

      <div className="aboutUsPageContainer2">
        <h4 className="homePageContainer4-headMain">The Process</h4>
        <img
          src={imgAb}
          style={
            isMobile
              ? { position: "absolute", top: "39%" }
              : { position: "absolute", top: "39%" }
          }
        />
        <p className="aboutUsPageContainer2-text">
          We're crafting upscale, comprehensive preventive and curative
          solutions for optimal patient outcomes.
        </p>

        <div className="aboutUsPageContainer2-div">
          <div className="aboutUsPageContainer2-div1">
            <h6 className="aboutUsPageContainer2-h1">Patient-Engaged:</h6>
            <p className="aboutUsPageContainer2-text1">
              Empowering you with full knowledge throughout your journey.
            </p>

            <h6
              style={isMobile ? {} : { paddingTop: "18%" }}
              className="aboutUsPageContainer2-h1"
            >
              Personalized :
            </h6>
            <p className="aboutUsPageContainer2-text1">
              Tailored assessments and options for your unique needs.
            </p>

            <h6
              style={isMobile ? {} : { paddingTop: "30%" }}
              className="aboutUsPageContainer2-h1"
            >
              Tech-Progressive:
            </h6>
            <p className="aboutUsPageContainer2-text1">
              Harmonizing cutting-edge technology with human touch.
            </p>
          </div>

          <div className="aboutUsPageContainer2-div2">
            <div className="aboutUsPageContainer2-div2-imgDiv1">
              <img
                src={isMobile ? abcdImg : img1}
                className="aboutUsPage-img1"
              />
              <img src={img2} className="aboutUsPage-img2" />
            </div>
            {!isMobile && (
              <div className="aboutUsPageContainer2-div2-imgDiv2">
                <img src={img3} className="aboutUsPage-img1" />
                <img src={img4} className="aboutUsPage-img1" />
                <img src={img5} className="aboutUsPage-img1" />
              </div>
            )}
          </div>
        </div>
      </div>

      {isMobile ? (
        <img src={bg2M} className="aboutUsPageImg2" />
      ) : (
        <img src={bg2} className="aboutUsPageImg2" />
      )}

      {!isMobile ? (
        <img src={whyChooseUsImg} />
      ) : (
        <img src={mobileWhy} style={{ margin: "16px 0px", width: "100%" }} />
      )}

      {/* {isMobile ? (
        <>
          <div className="aboutUsPageContainer3">
            <img src={bgM} style={{ width: "100%" }} />
            <div className="aboutUsPageContainer3-M">
              <h4
                style={{ padding: "16px 0px" }}
                className="aboutUsPageContainer3-h1"
              >
                Meet our <br /> team members
              </h4>
              <p className="aboutUsPageContainer3-t1">
                Our team embodies our values by prioritising integrity, empathy,
                and innovation, ensuring every decision and action aligns with
                our commitment to excellence.
              </p>

              {isMobile ? (
                <div
                  style={{ margin: `0rem 0rem 0rem ${value}rem` }}
                  className="aboutUsPageContainer3-alignIcons"
                >
                  {alignIconsMobile()}
                </div>
              ) : (
                <div
                  style={{ margin: `0rem 0rem 0rem ${value}rem` }}
                  className="aboutUsPageContainer3-alignIcons"
                >
                  {alignIcons()}
                </div>
              )}

              <div className="aboutUsPageContainer3-sliderContMain">
                <div className="aboutUsPageContainer3-alignIcons-pointer">
                  <img src={merger} />
                </div>
                {alignData()}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="aboutUsPageContainer3">
          <img src={bg} style={{ width: "100%" }} />

          <div className="aboutUsPageContainer3-div">
            <div className="aboutUsPageContainer3-cont1">
              <h4 className="aboutUsPageContainer3-h1">
                Meet our team members
              </h4>
              <p className="aboutUsPageContainer3-t1">
                Our team embodies our values by prioritising integrity, empathy,
                and innovation, ensuring every decision and action aligns with
                our commitment to excellence.
              </p>
            </div>

            <div
              style={{ margin: `0rem 0rem 0rem ${value}rem` }}
              className="aboutUsPageContainer3-alignIcons"
            >
              {alignIcons()}
            </div>

            <div className="aboutUsPageContainer3-sliderContMain">
              <div className="aboutUsPageContainer3-alignIcons-pointer">
                <img src={merger} />
              </div>
              {alignData()}
              <div className="aboutUsPageContainer3-arrow1">
                <ArrowRightAltIcon onClick={handleRightButtonClick} />
              </div>
              <div className="aboutUsPageContainer3-arrow2">
                <ArrowRightAltIcon onClick={handleLeftButtonClick} />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default AboutUsPage;
