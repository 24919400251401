import { useContext, useRef, useState } from "react";
import { Context } from "../../store/Store";
import CareGiverAd from "../../components/CareGiverAd/CareGiverAd";
import { selectIsMobile } from "../../store/global/Global.selectors";
import AdEnquiryContainer from "../../shared/containers/AdEnquiryContainer/AdEnquiryContainer";
import {
  ADD_ENQUIRY,
  CUSTOMER_LOGIN,
  VERIFY_OTP,
} from "../../shared/containers/ContactUsContainer/ContactUsContainer.constants";
import { APIPostRequest } from "../../config/HttpClient";
import { Loader } from "../../shared/components/Loader/Loader";
import SuccessSnackbar from "../../shared/components/Snackbars/SuccessSnackbar";
import FailureSnackbar from "../../shared/components/Snackbars/FailureSnackbar";
import { API_FAILURE_MESSAGE } from "../../store/global/Global.constants";
import img1 from "../../assets/AdImages/Frame 427320484.svg";
import { useHistory } from "react-router-dom";
import { CARE_GIVER_THANK_YOU } from "../../routes/constants";
import img1a from "../../components/CareGiverAd/images/Frame 427320598.png";
import img2 from "../../components/CareGiverAd/images/Frame 427320605.png";
import img3 from "../../components/CareGiverAd/images/Frame 427320609.png";
import img4 from "../../components/CareGiverAd/images/Frame 427320617.png";
import img5 from "../../components/CareGiverAd/images/Frame 427320620.png";
import img6 from "../../components/CareGiverAd/images/Frame 427320622.png";
import img7 from "../../assets/caregiverAdImages/Frame 427320545.svg";
import img8 from "../../assets/caregiverAdImages/Frame 427320539.svg";
import img9 from "../../assets/caregiverAdImages/Frame 427320540.svg";
import img10 from "../../assets/caregiverAdImages/OBJECTS.svg";
import img11 from "../../assets/caregiverAdImages/Group.svg";
import img12 from "../../assets/caregiverAdImages/Frame 427320541.svg";
import img1b from "../../components/CareGiverAd/images/iv caregive rmobile-1.png";
import img2b from "../../components/CareGiverAd/images/iv caregive rmobile-2.png";
import img3b from "../../components/CareGiverAd/images/iv caregive rmobile-3.png";
import img4b from "../../components/CareGiverAd/images/iv caregive rmobile-4.png";
import img5b from "../../components/CareGiverAd/images/iv caregive rmobile-5.png";
import img6b from "../../components/CareGiverAd/images/iv caregive rmobile.png";
import { POLICIES } from "../../shared/containers/FooterContainer/Footer.constants";

const CareGiverAdContainer = () => {
  const history = useHistory();
  const head = "Caregiver At Home";
  const text =
    "Empathetic experts offer tailored aid, empowering independence and nurturing well-being through personalised support and care.";
  const { state } = useContext(Context);
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileErr, setMobileError] = useState("");
  const [otpErr, setOtpError] = useState("");
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showFailureSnackbar, setShowFailureSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [bgHeight, setBgHeight] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [checked, setChecked] = useState(true);

  const [show, setShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else if (input.length < 10) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Mobile Number must be of 10 digits");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
  };

  const handleSendOtp = () => {
    if (checked && name.length > 1 && mobileNumber.length === 10) {
      var formData = new FormData();
      formData.append("data", `{"mobileNumber":"${mobileNumber}"}`);
      APIPostRequest(
        CUSTOMER_LOGIN,
        formData,
        (response) => {
          console.log("Customer logged in", response);
          setCustomerId(response.customerId);
          setShow(true);
          setTimeLeft(60);
          const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
              if (prevTime === 0) {
                clearInterval(timer);
                return 0;
              }
              return prevTime - 1;
            });
          }, 1000);
          setOtpError("");
          setBgHeight(true);
        },
        (error) => {
          console.log(error);
          setLimitError(true);
        }
      );
    }
  };

  const verifyOTP = () => {
    if (checked) {
      const enteredOTP = otp.join("");
      var formData = new FormData();
      formData.append(
        "data",
        `{"customerId":"${customerId}", "otp": ${enteredOTP}}`
      );
      APIPostRequest(
        VERIFY_OTP,
        formData,
        (response) => {
          console.log("OTP VERIFIED RESPONSE", response);
          handleSendEnquiry();
        },
        (error) => {
          console.log(error);
          setOtpError("Incorrect OTP");
          setOTP(["", "", "", "", "", ""]);
          console.log("Incorrect OTP, please try again.");
        }
      );
    }
  };

  const handleSendEnquiry = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append(
      "data",
      `{"customerId":"${customerId}", "name": "${name}", "mobileNumber": "${mobileNumber}", "lookingFor": "11", "enquiryFrom": "${
        selectIsMobile(state) ? "Mobile-web" : "Website"
      }","preferredDate": "","preferredTime": ""}`
    );
    APIPostRequest(
      ADD_ENQUIRY,
      formData,
      (response) => {
        console.log("OTP VERIFIED RESPONSE", response);
        setSnackbarMessage("Enquiry added successfully!");
        setShowSuccessSnackbar(true);
        setLoading(false);
        setShow(false);
        setBgHeight(false);
        setMobileNumber(" ");
        setName(" ");
        history.push(CARE_GIVER_THANK_YOU);
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setBgHeight(false);
        setSnackbarMessage(API_FAILURE_MESSAGE);
        setShowFailureSnackbar(true);
      }
    );
  };

  const handleChange = (index, value) => {
    if (!isNaN(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);
      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const bgArr = [
    {
      head: "IV/Immunization Care",
      textArr: [
        "Skilled professionals",
        "Intravenous treatments and immunizations",
        "Accurate dosages ",
        "Patient comfort",
      ],
      price: 499,
      mobImg: img1b,
      webImg: img3,
    },
    {
      head: "Wound Care",
      textArr: [
        "Trained caregivers",
        "Wound care",
        "Promoting healing",
        "Preventing complications",
      ],
      price: 499,
      mobImg: img2b,
      webImg: img1a,
    },
    {
      head: "Respite Care",
      textArr: [
        "Dedicated caregivers",
        "Continuous support",
        "Supervision for individuals with special needs",
        "Disability care",
      ],
      price: 999,
      mobImg: img3b,
      webImg: img2,
    },
    {
      head: "Transportation Care",
      textArr: [
        "Reliable caregivers",
        "Safe & timely transportation",
        "Medical appointments, errands, or outings",
        "Comfort and assistance throughout",
      ],
      price: 1199,
      mobImg: img4b,
      webImg: img4,
    },
    {
      head: "Day/Night Care (12 hours)",
      textArr: [
        "Supportive caregivers",
        "Round-the-clock assistance",
        "Comfort and Safety",
        "12 hour Supervision",
      ],

      price: 999,
      mobImg: img5b,
      webImg: img5,
    },
    {
      head: "Full day care (24 hours)",
      textArr: [
        "Compassionate caregivers",
        "Comprehensive care ",
        "Support around the clock",
        "Addressing daily needs",
      ],
      price: 1199,
      mobImg: img6b,
      webImg: img6,
    },
  ];

  const arr = [
    {
      label: "Quick time to hire",
      refImg: img7,
    },
    {
      label: "comprehensive screening",
      refImg: img8,
    },
    {
      label: "expertise of caregivers",
      refImg: img9,
    },
    {
      label: "emergency preparedness",
      refImg: img10,
    },
    {
      label: "24/7 health manager",
      refImg: img11,
    },
    {
      label: "ease of replacement",
      refImg: img12,
    },
  ];

  const handlePolicy = (policyType) => {
    setLoading(true);
    var formData = new FormData();
    formData.append("data", `{"policyFlag":${policyType}}`);
    APIPostRequest(
      POLICIES,
      formData,
      (response) => {
        setLoading(false);
        window.open(response?.policyLink, "_blank");
        console.log("Policy RESPONSE", response);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const handleCloseLimitDialog = () => {
    setLimitError(false);
  };

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {showSuccessSnackbar && (
            <SuccessSnackbar
              message={snackbarMessage}
              showSuccessSnackbar
              setShowSuccessSnackbar={setShowSuccessSnackbar}
            />
          )}
          {showFailureSnackbar && (
            <FailureSnackbar
              message={snackbarMessage}
              showFailureSnackbar
              setShowFailureSnackbar={setShowFailureSnackbar}
            />
          )}
          <div style={{ backgroundColor: "#FFFBF4" }}>
            <AdEnquiryContainer
              head={head}
              text={text}
              isMobile={selectIsMobile(state)}
              inputRefs={inputRefs}
              otp={otp}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              formatTime={formatTime}
              timeLeft={timeLeft}
              handleSendOtp={handleSendOtp}
              show={show}
              verifyOTP={verifyOTP}
              setName={setName}
              handleMobile={handleMobile}
              mobileNumber={mobileNumber}
              mobileErr={mobileErr}
              otpErr={otpErr}
              bgHeight={bgHeight}
              bgImg={img1}
              checked={checked}
              setChecked={setChecked}
              handlePolicy={handlePolicy}
              limitError={limitError}
              handleCloseLimitDialog={handleCloseLimitDialog}
            />
          </div>
          <div style={{ backgroundColor: "#FFFBF4" }}>
            <CareGiverAd
              isMobile={selectIsMobile(state)}
              arr={arr}
              bgArr={bgArr}
            />
          </div>
        </>
      )}
    </>
  );
};

export default CareGiverAdContainer;
