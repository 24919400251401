import { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import dayjs from "dayjs";
import img1 from "../../assets/medicalImages/genersl wellness bg 1.svg";
import img2 from "../../assets/medicalImages/genersl wellness bg 1 (1).svg";
import img3 from "../../assets/medicalImages/genersl wellness bg 1 (2).svg";
import img4 from "../../assets/medicalImages/genersl wellness bg 1 (3).svg";
import img5 from "../../assets/medicalImages/genersl wellness bg 1 (4).svg";
import img6 from "../../assets/medicalImages/genersl wellness bg 1 (5).svg";
import img7 from "../../assets/medicalImages/genersl wellness bg 1 (6).svg";
import img8 from "../../assets/medicalImages/genersl wellness bg 1 (7).svg";
import img9 from "../../assets/medicalImages/Frame 427319362.svg";
import img10 from "../../assets/medicalImages/Group 35642.svg";
import img11 from "../../assets/medicalImages/Frame 427319363.svg";
import img12 from "../../assets/medicalImages/Frame 427319366.svg";
import img13 from "../../assets/medicalImages/Frame 427319365.svg";
import img14 from "../../assets/medicalImages/Frame 427319364.svg";
import MedicalEquipmentPage from "../../components/MedicalEquipmentPage/MedicalEquipmentPage";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";

const MedicalEquipmentServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "8";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img1,
      head: "Respiratory Care",
      text: "Oxygen Concentrator, Nebulizer, Steamer, CPAP, BiPAP Machine, Pulse Oxymeter",
    },
    {
      arrImg: img2,
      head: "Orthopedics Care",
      text: "Heating Pad/Belt, L S Belt, Knee Cap, Shoulder Arm Support, Recliner",
    },

    {
      arrImg: img3,
      head: "Sleep Care",
      text: "CPAP Machines, Positional Sleep Aids, Sleep Pillows, Humidifiers, Oral Appliances",
    },
    {
      arrImg: img4,
      head: "Wellness & Care",
      text: "Digital B P Monitor, Glucometer, Face Mask, Adult Diapers, Executive Bank Reset",
    },
    {
      arrImg: img5,
      head: "Hospital Beds",
      text: "High-quality hospital beds offered by reputed brands",
    },
    {
      arrImg: img6,
      head: "Air mattresses",
      text: "Mattresses designed specially for bedridden patients",
    },
    {
      arrImg: img7,
      head: "Mobility",
      text: "Wheelchair, Monopod Stick, Quadripod Stick, Walkers, Under Arm Crutches",
    },
    {
      arrImg: img8,
      head: "bathroom Accessories",
      text: "Commode Chair, Toilet Raiser, Urinal Pot, Bed Pan, Shower Chair",
    },
  ];

  const arr1 = [
    {
      arrImg: img9,
      head: "Buy Back Guarantee*",
      text: "We ensure investment security, offering reimbursement or credit for returned equipment within specified terms, safeguarding your purchase.",
    },
    {
      arrImg: img10,
      head: "Hassle Free Delivery",
      text: "Enjoy hassle-free doorstep delivery, ensuring convenience and peace of mind, with our team handling transportation and setup for your equipment.",
    },

    {
      arrImg: img11,
      head: "Quality Assured",
      text: "Trust in branded and quality-assured equipment, meticulously selected to meet industry standards, ensuring reliability, durability, and optimal performance for your needs.",
    },
    {
      arrImg: img12,
      head: "Free installation",
      text: "Benefit from complementary installation services, expertly executed by our trained professionals, ensuring proper setup and functionality of your equipment at no additional cost.",
    },
    {
      arrImg: img13,
      head: "24/7 Support*",
      text: "Our medical equipment comes with round-the-clock support, ensuring reliability and peace of mind for healthcare professionals.",
    },
    {
      arrImg: img14,
      head: "Cost effective",
      text: "We offer cost-effective solutions without compromising quality, enabling efficient healthcare delivery within budget constraints.",
    },
  ];

  const dropDownArr = [
    {
      label: "Do you provide equipment on rent or do I have to purchase it?",
      text: "We understand your dilemma. Both renting and buying are viable options. Consider your needs and preferences. If you opt for buying, we offer buy-back options for longer durations.",
    },
    {
      label:
        "I need the product immediately. How much time does delivery take?",
      text: "We strive for efficiency. For your convenience, we offer same-day delivery within Delhi/NCR if orders are confirmed before 2 PM*. ",
    },
    {
      label:
        "What standards are in place to ensure the equipment is cleaned before the next rental?",
      text: "Your safety is our priority. Equipment undergoes meticulous cleaning and disinfection, ensuring your peace of mind. We conduct thorough service and safety checks before every delivery.",
    },
    {
      label: "Can I extend my rental?",
      text: "Flexibility is key. Your rental automatically renews monthly for your convenience. Just give us a heads-up at least two business days before you plan to return it.",
    },
    {
      label: "What payment methods do you accept?",
      text: "We value your convenience. Choose from various payment methods including cash, cheque, NEFT, PayTM, Google Pay, and other online banking options. Your preferred method is our priority.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <MedicalEquipmentPage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default MedicalEquipmentServiceContainer;
