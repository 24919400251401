import { useContext, useEffect, useState } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import img1 from "../../assets/careGiverImages/Frame 427319332.svg";
import img2 from "../../assets/careGiverImages/Frame 427319333.svg";
import img3 from "../../assets/careGiverImages/Frame 427319334.svg";
import img4 from "../../assets/careGiverImages/Frame 427319336.svg";
import img5 from "../../assets/careGiverImages/Group 35638.svg";
import img6 from "../../assets/careGiverImages/Frame 427319335.svg";
import CareGiverServicePage from "../../components/CareGiverServicePage/CareGiverServicePage";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import img7 from "../../assets/careGiverImages/Rectangle 2574.png";
import img8 from "../../assets/careGiverImages/Rectangle 2574 (1).png";
import img9 from "../../assets/careGiverImages/Rectangle 2574 (2).png";
import img10 from "../../assets/careGiverImages/Rectangle 2574 (3).png";
import img11 from "../../assets/careGiverImages/Rectangle 2574 (4).png";
import img12 from "../../assets/careGiverImages/Rectangle 2574 (5).png";
import img13 from "../../assets/careGiverImages/mobile/Rectangle 2574 (5).jpg";
import img14 from "../../assets/careGiverImages/mobile/Rectangle 2574.jpg";
import img15 from "../../assets/careGiverImages/mobile/Rectangle 2574 (1).jpg";
import img16 from "../../assets/careGiverImages/mobile/Rectangle 2574 (2).jpg";
import img17 from "../../assets/careGiverImages/mobile/Rectangle 2574 (3).jpg";
import img18 from "../../assets/careGiverImages/mobile/Rectangle 2574 (4).jpg";
import img19 from "../../assets/careGiverImages/mobile/Frame 427319372.png";
import img20 from "../../assets/careGiverImages/mobile/Frame 427319373.png";
import img21 from "../../assets/careGiverImages/mobile/Frame 427319374.png";
import img22 from "../../assets/careGiverImages/mobile/Frame 427319373.png";
import img23 from "../../assets/careGiverImages/mobile/Frame 427319375.png";
import img24 from "../../assets/careGiverImages/mobile/Frame 427319376.png";

import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
const CareGiverServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "7";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr1 = [
    {
      arrImg: img1,
      arrMobImg: img19,
      head: "Quick hire",
      text: "Swift time-to-hire ensures prompt assistance, matching you with qualified caregivers efficiently, minimising delays in receiving crucial support.",
    },
    {
      arrImg: img2,
      arrMobImg: img20,
      head: "Comprehensive Screening",
      text: "Rigorous screening process assures trustworthy caregivers, vetted for qualifications, experience, and character, ensuring safety and reliability for your loved ones.",
    },

    {
      arrImg: img3,
      arrMobImg: img21,
      head: "Expertise of caregivers",
      text: "Caregivers possess specialised expertise and training, equipped with skills and knowledge to address diverse needs and provide quality care.",
    },
    {
      arrImg: img4,
      arrMobImg: img23,
      head: "Emergency Preparedness",
      text: "Emergency preparedness ensures caregivers are trained in first aid and basic life support (BLS), ready to respond effectively in critical situations, prioritising safety and well-being.",
    },
    {
      arrImg: img5,
      arrMobImg: img21,
      head: "Ease of Replacement",
      text: "Hassle-free replacement guarantees seamless continuity of care, providing peace of mind with prompt replacement of caregivers when needed.",
    },
    {
      arrImg: img6,
      arrMobImg: img24,
      head: "24/7 Health Manager Support",
      text: "Access 24/7 support from our health manager, ensuring continuous assistance, guidance, and coordination of care, enhancing responsiveness and satisfaction for clients and families.",
    },
  ];

  const bgArr = [
    {
      head: "IV/Immunization Care",
      textArr: [
        "Skilled professionals",
        "Intravenous treatments and immunizations",
        "Accurate dosages ",
        "Patient comfort",
      ],
      bgImage1: img13,
      bgImage: img7,
      price: 499,
    },
    {
      head: "Wound Care",
      textArr: [
        "Trained caregivers",
        "Wound care",
        "Promoting healing",
        "Preventing complications",
      ],
      bgImage1: img14,
      bgImage: img8,
      price: 499,
    },
    {
      head: "Respite Care",
      textArr: [
        "Dedicated caregivers",
        "Continuous support",
        "Supervision for individuals with special needs",
        "Disability care",
      ],
      bgImage1: img15,
      bgImage: img9,
      price: 999,
    },
    {
      head: "Transportation Care",
      textArr: [
        "Reliable caregivers",
        "Safe & timely transportation",
        "Medical appointments, errands, or outings",
        "Comfort and assistance throughout",
      ],
      bgImage1: img16,
      bgImage: img10,
      price: 1199,
    },
    {
      head: "Day/Night Care (12 hours)",
      textArr: [
        "Supportive caregivers",
        "Round-the-clock assistance",
        "Comfort and Safety",
        "12 hour Supervision",
      ],
      bgImage1: img17,
      bgImage: img11,
      price: 999,
    },
    {
      head: "Full day care (24 hours)",
      textArr: [
        "Compassionate caregivers",
        "Comprehensive care ",
        "Support around the clock",
        "Addressing daily needs",
      ],
      bgImage1: img18,
      bgImage: img12,
      price: 1199,
    },
  ];

  const dropDownArr = [
    {
      label: "What caregiver categories do you provide?",
      text: "Medical Caregiver: Offers physical and mental support for daily activities.",
    },
    {
      label: "How do you ensure caregiver quality?",
      text: "We maintain strict quality control and monitor caregivers regularly, welcoming client feedback for continuous improvement. Periodic audits of all caregivers are also conducted.",
    },
    {
      label: "How are your caregivers selected and trained?",
      text: "Rigorous interviews, police background checks, and in-office training covering medical and soft skills. We provide our caregivers qualification certificates.",
    },
    {
      label: "What types of care services do you offer?",
      text: "Our caregivers provide transportation, respite, immunisation, IV, wound care, medication management, vital sign monitoring, post-surgical care, tracheostomy, and catheterization care.",
    },
    {
      label: "What does specialised care at home include?",
      text: "Tailored for heart disease, kidney disease, dementia, or serious illnesses. Services cover health tests, education, prescription medications, wound care, physical therapy, emotional support, and healthcare navigation assistance.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <CareGiverServicePage
        isMobile={selectIsMobile(state)}
        arr1={arr1}
        bgArr={bgArr}
        handleEnquireNow={handleEnquireNow}
      />
      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>

      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default CareGiverServiceContainer;
