import React from "react";
import "./BlogPage.css";
import img1 from "./Rectangle 2489.svg";
import img2 from "./Rectangle 2491.svg";
import img3 from "./image 50.svg";
import img4 from "./Rectangle 2538 (1).svg";

const BlogPage = (props) => {
  const { blogs, handleClick, isMobile } = props;
  return (
    <>
      <div className="blogPageContainer">
        <div onClick={() => handleClick(0)} className="blogPageContainer-divL">
          <img className="blog-img" src={blogs[0].bImg} alt="blogImage" />
          <div className="blogPageContainer-divL-content">
            <button className="blog-Btn">{blogs[0].title}</button>
            <h4 style={{ color: "#fff" }} className="blog-content">
              {blogs[0].content}
            </h4>
            <p className="blog-content1">
              {blogs[0].publishedDate} | {blogs[0].duration} min read
            </p>
          </div>
        </div>
        <div className="blogPageContainer-divR">
          <div
            onClick={() => handleClick(1)}
            className="blogPageContainer-divR-divs"
          >
            <img className="blog-img" src={blogs[1].bImg} alt="blogImage" />
            <div className="blogPageContainer-divR-content">
              <button className="blog-BtnR">{blogs[1].title}</button>
              <h4 className="blog-contentR">{blogs[1].content}</h4>
              <p className="blog-content1R">
                {blogs[1].publishedDate} | {blogs[1].duration} min read
              </p>
            </div>
          </div>
          <div
            onClick={() => handleClick(2)}
            className="blogPageContainer-divR-divs"
          >
            <img className="blog-img" src={blogs[2].bImg} alt="blogImage" />
            <div className="blogPageContainer-divR-content">
              <button className="blog-BtnR">{blogs[2].title}</button>
              <h4 className="blog-contentR">{blogs[2].content}</h4>
              <p className="blog-content1R">
                {blogs[2].publishedDate} | {blogs[2].duration} min read
              </p>
            </div>
          </div>
          <div
            onClick={() => handleClick(4)}
            className="blogPageContainer-divR-divs"
          >
            <img className="blog-img" src={blogs[3].bImg} alt="blogImage" />
            <div className="blogPageContainer-divR-content">
              <button className="blog-BtnR">{blogs[3].title}</button>
              <h4 className="blog-contentR">{blogs[3].content}</h4>
              <p className="blog-content1R">
                {blogs[3].publishedDate} | {blogs[3].duration} min read
              </p>
            </div>
          </div>
          <div
            onClick={() => handleClick(3)}
            className="blogPageContainer-divR-divs"
          >
            <img className="blog-img" src={blogs[4].bImg} alt="blogImage" />
            <div className="blogPageContainer-divR-content">
              <button className="blog-BtnR">{blogs[4].title}</button>
              <h4 className="blog-contentR">{blogs[4].content}</h4>
              <p className="blog-content1R">
                {blogs[4].publishedDate} | {blogs[4].duration} min read
              </p>
            </div>
          </div>
        </div>

        {isMobile ? (
          <>
            <div onClick={() => handleClick(1)} className="blogMobile">
              <div className="blogMobile1">
                <h4 className="blog-content">{blogs[1].content}</h4>
                <button className="mBtnBlog">{blogs[1].title}</button>

                <p className="blog-content1">
                  {blogs[1].publishedDate} | {blogs[1].duration} min read
                </p>
              </div>
              <img src={img3} className="mblog-img" />
            </div>

            <div  onClick={() => handleClick(2)} className="blogMobile">
              <div className="blogMobile1">
                <h4 className="blog-content">{blogs[2].content}</h4>
                <button className="mBtnBlog">{blogs[2].title}</button>

                <p className="blog-content1">
                  {blogs[2].publishedDate} | {blogs[2].duration} min read
                </p>
              </div>
              <img src={img1} className="mblog-img" />
            </div>

            <div  onClick={() => handleClick(4)} className="blogMobile">
              <div className="blogMobile1">
                <h4 className="blog-content">{blogs[3].content}</h4>
                <button className="mBtnBlog">{blogs[3].title}</button>

                <p className="blog-content1">
                  {blogs[3].publishedDate} | {blogs[3].duration} min read
                </p>
              </div>
              <img src={img2} className="mblog-img" />
            </div>

            <div  onClick={() => handleClick(3)} className="blogMobile">
              <div className="blogMobile1">
                <h4 className="blog-content">{blogs[4].content}</h4>
                <button className="mBtnBlog">{blogs[4].title}</button>

                <p className="blog-content1">
                  {blogs[4].publishedDate} | {blogs[4].duration} min read
                </p>
              </div>
              <img src={img4} className="mblog-img" />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default BlogPage;
