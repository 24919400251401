import React from "react";
import "./Footer.css";
import applePlayImg from "./image 105.svg";
import googlePlayImg from "./image 104.svg";
import facebook from "../../../assets/headerIcons/fb2.svg";
import linkedIn from "../../../assets/headerIcons/linekdin2.svg";
import mail from "../../../assets/headerIcons/mail2.svg";
import twitter from "../../../assets/headerIcons/twitter 12.svg";
import youtube from "../../../assets/headerIcons/Layer_12.svg";
import insta from "../../../assets/headerIcons/Frame2.svg";
function Footer(props) {
  const {
    instantLabels,
    quickLinksLabels,
    ourServicesLabels,
    handleClick,
    showDialog,
    handleDialog,
    handleChange,
    subscribeInput,
    handleSubscribe,
    handlePolicy,
  } = props;

  return (
    <>
      <div style={{ position: "relative" }} className="footerContainer">
        <div className="footerContainerDiv1">
          <div>
            <h4 className="footerContainerDiv1-h">Quick Links</h4>
            <div>
              {quickLinksLabels.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item.link)}
                    className="footerContainerDiv1-p"
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>

          <div>
            <h4 className="footerContainerDiv1-h">Our Services</h4>
            <div>
              {ourServicesLabels.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item.link)}
                    className="footerContainerDiv1-p"
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>

          <div>
            <h4 className="footerContainerDiv1-h">Get Instantly</h4>
            <div>
              {instantLabels.map((item) => {
                return (
                  <p
                    onClick={() => handleClick(item.link)}
                    className="footerContainerDiv1-p"
                  >
                    {item.name}
                  </p>
                );
              })}
            </div>
          </div>
          <div>
            <h4 className="footerContainerDiv1-h">Address</h4>
            <div>
              <div className="footerAddress">
                <p className="footerAddress-p">
                  DLF Phase IV, Gurugram, Haryana 122002
                </p>
                <p className="footerAddress-p">connect@hekahealthyyou.com</p>
                <p className="footerAddress-p">+91 801 0711 010</p>
              </div>
              <div className="footerContainerDiv2-div2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28059.941346533644!2d77.06388039805813!3d28.464706076352893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18dc634e8939%3A0xfec8c235f5d1e002!2sHeka%20Healthy%20You!5e0!3m2!1sen!2sin!4v1709393596630!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  className="footerMap"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="footerContainerDiv2-div1">
                <a
                  target="_blank"
                  href="https://www.facebook.com/HekaHealthyYou?mibextid=ZbWKwL"
                >
                  <img src={facebook} />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/heka-healthy-you/"
                >
                  <img src={linkedIn} />
                </a>
                <a target="_blank" href="mailto:support@hekahealth.in">
                  <img src={mail} />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@hekahealthyyouprivatelimited/featured"
                >
                  <img src={youtube} />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/heka__health?igsh=aXdhaTdlencyNnc="
                >
                  <img src={insta} />
                </a>
                <a
                  target="_blank"
                  href="https://x.com/hekahealthyyou?t=bIVd2LIDtxooXT_znSYiMA&s=08"
                >
                  <img src={twitter} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: "60%",
            left: "10%",
            gap: "16px",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "27px",
              textAlign: "left",
            }}
          >
            Download the app
          </p>
          <div style={{ display: "flex", gap: "8px" }}>
            <img src={googlePlayImg} />
            <img src={applePlayImg} />
          </div>
        </div>
      </div>

      <div className="footerContainerLst">
        <p onClick={() => handleDialog()} className="footerAddress-p">
          Subscribe{" "}
        </p>
        <p onClick={() => handlePolicy(1)} className="footerAddress-p">
          Privacy Policy
        </p>
        <p onClick={() => handlePolicy(2)} className="footerAddress-p">
          Terms of Use
        </p>
        <p onClick={() => handlePolicy(3)} className="footerAddress-p">
          Payment Policy
        </p>
        {showDialog ? (
          <>
            <div className="footerBox">
              <div>
                <div className="footerBox-h">Subscribe to our Newsletter!</div>
                <p className="footerBox-t">
                  From business updates to future plans to upcoming events, HHY
                  newsletter <br /> has it all. Subscribe today to access this
                  information and more.
                </p>
              </div>
              <div className="footerBox-alignbtn">
                <input
                  value={subscribeInput.value}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your Email"
                  className="footerBox-input"
                />
                {subscribeInput.error && (
                  <p className="footerBox-inputError">{subscribeInput.error}</p>
                )}
                <button
                  onClick={() => handleSubscribe()}
                  className="footerBox-btn11"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Footer;
