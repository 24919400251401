import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import dadi from "../../../assets/homePageImages/Vector.svg";
const FaqComponent = (props) => {
  const { arr, isMobile } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleIndex = (i) => {
    setSelectedIndex(i);
  };
  return (
    <>
      <div className="homePageContainer4-headMainCont">
        <h4 className="homePageContainer4-headMain">
          Frequently Asked <br /> Questions
        </h4>
        {isMobile ? (
          <></>
        ) : (
          <img className="homePageContainer4-headMain-dadi2" src={dadi} />
        )}
      </div>
      <div className="homePageContainer5">
        {arr.map((item, index) => {
          return (
            <div
              key={index}
              className={
                selectedIndex === index
                  ? "homePageContainer5-BoxBorder"
                  : "homePageContainer5-Box"
              }
            >
              <div
                onClick={() => handleIndex(index)}
                className="homePageContainer5-div"
              >
                <div className="homePageContainer5-head">{item.label}</div>
                {selectedIndex === index ? (
                  <div
                    style={{
                      backgroundColor: "#215071",
                      color: "#fff",
                      borderRadius: "100%",
                      padding: isMobile ? "0px" : "9px",
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      borderRadius: "100%",
                      padding: isMobile ? "0px" : "9px",
                    }}
                  >
                    <KeyboardArrowRightIcon />
                  </div>
                )}
              </div>
              {selectedIndex === index && (
                <p className="homePageContainer5-text">{item.text}</p>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default FaqComponent;
