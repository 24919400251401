import React, { useContext, useEffect, useRef, useState } from "react";
import HomePage from "../../components/HomePage/template";
import { selectHomePageLabels } from "./HomePageContainer.selector";
import { makeStyles } from "@material-ui/core";
import { Context } from "../../store/Store";
import elderlyCareImg from "../../assets/homePageImages/serviceCards/elderly care card.jpg";
import generalWellnessImg from "../../assets/homePageImages/serviceCards/general wellness.jpg";
import operativeCareImg from "../../assets/homePageImages/serviceCards/operative care card.jpg";
import pregnancyCareImg from "../../assets/homePageImages/serviceCards/pregnancy care card.jpg";
import safetyImg from "../../assets/homePageImages/serviceCards/safety training card.jpg";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";
import HeaderDrawer from "../../shared/components/Header/HeaderDrawer";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import {
  ELDERLY_CARE_SERVICE_PAGE,
  GENERAL_WELLNESS_SERVICE_PAGE,
  OPERATIVE_CARE_SERVICE_PAGE,
  PREGNANCY_CARE_SERVICE_PAGE,
  SAFETY_TRAINING_SERVICE_PAGE,
} from "../../routes/constants";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  progressbar: {
    color: theme.palette.secondary.light,
  },
}));

const HomePageContainer = () => {
  const history = useHistory();
  const { state } = useContext(Context);
  console.log(selectIsMobile(state), "selectIsMobile");
  const pageIndex = "0";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("ON HOME PAGE");

  const serviceCardsData = [
    {
      heading: "Elderly Care",
      images: elderlyCareImg,
      text: "Compassionate support tailored to the unique needs of seniors, ensuring comfort and well-being.",
      url: ELDERLY_CARE_SERVICE_PAGE,
    },
    {
      heading: "Pregnancy Care",
      images: pregnancyCareImg,
      text: "Expert guidance and care throughout the journey to parenthood, prioritizing maternal and fetal health.",
      url: PREGNANCY_CARE_SERVICE_PAGE,
    },
    {
      heading: "Operative Care",
      images: operativeCareImg,
      text: "Advanced treatment plans for optimal surgical outcomes and recovery.",
      url: OPERATIVE_CARE_SERVICE_PAGE,
    },
    {
      heading: "Safety Trainings",
      images: safetyImg,
      text: "Empowering everyone with the basic knowledge and skill to respond in health emergencies.",
      url: SAFETY_TRAINING_SERVICE_PAGE,
    },
  ];

  const [mobileNumber, setMobileNumber] = useState("");

  const [mobileErr, setMobileError] = useState("");

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const dropDownArr = [
    {
      label: "What does HHY offer?",
      text: "We offer preventive and curative solutions, including elderly care, pregnancy care, operative care, and medical astrology, prioritising your well-being.",
    },
    {
      label: "How does HHY function?",
      text: "We provide patient-engaged, personalised, and tech-progressive care, ensuring comfort, empathy, and comprehensive medical expertise for optimal outcome.",
    },
    {
      label: "How can I start your services?",
      text: "Contact us to begin your journey toward a healthier, happier you. Our team will guide you through the process.",
    },
    {
      label: "Why choose home health care?",
      text: "Home health care prioritises convenience, comfort, and personalised support, ensuring your unique needs are met with empathy and expertise.",
    },
    {
      label: "What all data does HHY store? Is it Safe?",
      text: "We store essential medical data securely, adhering to strict safety protocols to ensure confidentiality and peace of mind for our clients.",
    },
  ];

  const handleReadMore = (item) => {
    console.log("READ MORE", item);
    history.push(item.url);
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}

      {/* {isFetching ? (
        <Backdrop className={classes.backdrop} open={isFetching}>
          <CircularProgress className={classes.progressbar} color="primary" />
        </Backdrop>
      ) : (
       
      )} */}

      <HomePage
        isMobile={selectIsMobile(state)}
        homePageLabels={selectHomePageLabels(state)}
        serviceCardsData={serviceCardsData}
        mobileNumber={mobileNumber}
        handleMobile={handleMobile}
        mobileErr={mobileErr}
        handleReadMore={handleReadMore}
        pageIndex={pageIndex}
      />

      <FaqComponent isMobile={selectIsMobile(state)} arr={dropDownArr} />

      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />

      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
    </>
  );
};

export default HomePageContainer;
