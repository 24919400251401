import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../store/Store";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import ContactUsContainer from "../../shared/containers/ContactUsContainer/ContactUsContainer";
import SafetyTrainingServicePage from "../../components/SafetyTrainingServicePage/SafetyTrainingServicePage";
import { selectIsMobile } from "../../store/global/Global.selectors";
import FaqComponent from "../../shared/components/FaqComponent/FaqComponent";
import img1 from "../../assets/safetyImages/Frame 427319035.svg";
import img2 from "../../assets/safetyImages/Rectangle 2514.svg";
import img3 from "../../assets/safetyImages/image 104.svg";
import img4 from "../../assets/safetyImages/image 105.svg";
import img5 from "../../assets/safetyImages/image 106.svg";
import img6 from "../../assets/safetyImages/image 107.svg";
import img7 from "../../assets/safetyImages/image 108.svg";
import img8 from "../../assets/safetyImages/Group 35581.svg";
import img9 from "../../assets/safetyImages/Group 35585.svg";
import img10 from "../../assets/safetyImages/Group 35589.svg";
import img11 from "../../assets/safetyImages/Icon (8).svg";
import img12 from "../../assets/safetyImages/Icon (9).svg";
import img13 from "../../assets/safetyImages/Icon (10).svg";
import img14 from "../../assets/safetyImages/Icon (11).svg";
import img15 from "../../assets/safetyImages/Icon (12).svg";
import img16 from "../../assets/safetyImages/Icon (13).svg";
import img17 from "../../assets/safetyImages/Icon (14).svg";
import img18 from "../../assets/safetyImages/Icon (15).svg";
import MobileFloatingButton from "../../components/MobileFloatingButton/MobileFloatingButton";
import ChatBotContainer from "../../shared/containers/ChatBotContainer/ChatBotContainer";

const SafetyTrainingServiceContainer = () => {
  const { state, dispatch } = useContext(Context);
  const pageIndex = "2";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const arr = [
    {
      arrImg: img11,
      head: "Simulated Exercises",
      text: "Engage in lifelike scenarios for immersive skill enhancement.",
    },
    {
      arrImg: img12,
      head: "Trainer: Trainee",
      text: "The trainer to trainee ratio adhering to the standards set by the Indian Resuscitation Council (IRC).",
    },

    {
      arrImg: img13,
      head: "Quality Kits",
      text: " Elevate your training with premium-grade equipment, supplies like AED kits and mannequins.",
    },
    {
      arrImg: img14,
      head: "Certification",
      text: "Authenticate your expertise with a recognized certificate valid for 2 years.",
    },
    {
      arrImg: img15,
      head: "Information Manual",
      text: "Access a comprehensive handbook for learning support in both soft/hard copy.",
    },
    {
      arrImg: img16,
      head: "Multilingual",
      text: "Embrace inclusivity with training and manuals available in multiple languages.",
    },
    {
      arrImg: img17,
      head: "Customised Trainings",
      text: "Tailor-made programs designed to meet your specific objectives.",
    },
    {
      arrImg: img18,
      head: "Experienced Trainers",
      text: "Learn from seasoned and certified professionals dedicated to your success.",
    },
  ];

  const arr1 = [
    {
      arrImg: img1,
      text: "The bystander CPR rate in India is reported to be only 1.3%. This is far below the target goal of 62% set globally. With an estimated survival of less than 10%, OHCA (Out of Hospital Cardiac Arrests) remains one of India's leading causes of death.",
    },
    {
      arrImg: img8,
      text: "The number of road accidents in 2022 increased by 11.9% compared to 2021. Similarly, the number of deaths and injuries on account of road accidents also increased by 9.4% and 15.3% respectively.",
    },
    {
      arrImg: img9,
      text: "From 2001 to 2016, there were a total of 2347 choking deaths in children from 0 to 19 years. Children under five years of age accounted for 75% of choking fatalities.There was a decrease in the choking fatalities rate in all children (0.18/100,000 versus 0.16/100,000, respectively) but no change in fatalities rate for children under five.",
    },
    {
      arrImg: img10,
      text: "Up to 59% of pre-hospital injury deaths could be prevented with simple first aid interventions, yet despite 93% of people calling for an ambulance upon encountering an injured individual, actual first aid interventions remain infrequent.",
    },
  ];

  const arr2 = [
    {
      arrImg: img2,
    },
    {
      arrImg: img2,
    },
    {
      arrImg: img2,
    },
  ];

  const arr3 = [img3, img4, img5, img6, img7];

  const dropDownArr = [
    {
      label: "Why is this training essential for me?",
      text: "This training is essential as it equips you with vital skills to potentially save lives during emergencies, regardless of your background or profession.",
    },
    {
      label: "I am not a medical person, why will I take training?",
      text: "Regardless of your medical expertise, this training offers life-saving skills crucial in emergencies, ensuring you can confidently assist those in need.",
    },
    {
      label: "What's the certificate's duration?",
      text: "Certificates are valid for 2 years from training, ensuring ongoing competency and adherence to current life-saving standards.",
    },
    {
      label: "How long does it take to learn BLS and First Aid?",
      text: "Equipping one with Basic Life support and First Aid is a continuous process, however our engaging and more hands on training program helps you to acquire these skills within 8 hours, empowering you to respond efficiently as per standardised guidelines.",
    },
    {
      label: "What's covered in training sessions?",
      text: "Training covers CPR, AED operation, relief for choking and drowning victims, and essential First Aid, providing comprehensive skills to handle common emergencies.",
    },
  ];

  const handleEnquireNow = () => {
    const enqElement = document.querySelector(".enq");
    if (enqElement) {
      enqElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrolling = () => {
    window.scrollTo(0, 2000);
  };

  const [showChat, setShowChat] = useState(false);

  const handleShowChat = (i) => {
    setShowChat(true);
    setShowBtns(false);
  };

  const [showBtn, setShowBtns] = useState(false);

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <SafetyTrainingServicePage
        isMobile={selectIsMobile(state)}
        arr={arr}
        arr1={arr1}
        arr2={arr2}
        arr3={arr3}
        handleEnquireNow={handleEnquireNow}
      />

      <div className="enq">
        <ContactUsContainer pageIndex={pageIndex} />
      </div>
      <FaqComponent arr={dropDownArr} isMobile={selectIsMobile(state)} />
      <ChatBotContainer
        handleScrolling={handleScrolling}
        handleShowChat={handleShowChat}
        showChat={showChat}
        setShowChat={setShowChat}
        isMobile={selectIsMobile(state)}
      />
      <MobileFloatingButton
        handleShowChat={handleShowChat}
        isMobile={selectIsMobile(state)}
        showBtn={showBtn}
        setShowBtns={setShowBtns}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default SafetyTrainingServiceContainer;
