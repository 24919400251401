import React, { useContext, useEffect } from "react";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import BlogPage from "../../components/BlogPage/BlogPage";
import img1 from "../../assets/blogsImages/background and image.svg";
import img2 from "../../assets/blogsImages/background and image (1).svg";
import img3 from "../../assets/blogsImages/background and image (2).svg";
import img4 from "../../assets/blogsImages/background and image (3).svg";
import img5 from "../../assets/blogsImages/Frame 427320029.svg";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { Context } from "../../store/Store";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { useHistory } from "react-router-dom";
const BlogPageContainer = () => {
  const history = useHistory();
  const { state } = useContext(Context);
  const arr = [
    {
      title: "Elderly Care",
      content:
        "The Role of Wearable Health Tech in Elderly Fall Prevention : A Comprehensive Guide",
      publishedDate: "May 07th, 2024",
      duration: 5,
      bImg: img1,
    },
    {
      title: "Safety Trainings",
      content: "Empowering Employees: The Importance of Safety Training",
      publishedDate: "Mar 01st, 2024",
      duration: 5,
      bImg: img2,
    },
    {
      title: "Elderly Care",
      content:
        "Empowering Elderly Emotional Health: Strategies for Thriving in Later Life",
      publishedDate: "Apr 30th, 2024",
      duration: 5,
      bImg: img4,
    },
    {
      title: "Home Healthcare",
      content: "Reducing Healthcare Costs: The Value of Home Healthcare",
      publishedDate: "Apr 17th, 2024",
      duration: 5,
      bImg: img3,
    },
    {
      title: "Safety Trainings",
      content: "The Critical Role of Emergency Care: Ensuring Quick Response",
      publishedDate: "Mar 01st, 2024",
      duration: 5,
      bImg: img5,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (i) => {
    history.push({ pathname: "/blogs-details", state: { pageIndex: i } });
  };

  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <BlogPage
        isMobile={selectIsMobile(state)}
        blogs={arr}
        handleClick={handleClick}
      />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default BlogPageContainer;
