import { useContext, useState } from "react";
import BlogDetailPage from "../../components/BlogDetailPage/BlogDetailPage";
import FooterContainer from "../../shared/containers/FooterContainer/FooterContainer";
import HeaderContainer from "../../shared/containers/HeaderContainer/HeaderContainer";
import dayjs from "dayjs";
import MobileHeaderContainer from "../../shared/containers/MobileHeaderContainer/MobileHeaderContainer";
import MobileFooterContainer from "../../shared/containers/MobileFooterContainer/MobileFooterContainer";
import { selectIsMobile } from "../../store/global/Global.selectors";
import { Context } from "../../store/Store";
import { useLocation } from "react-router-dom";
const BlogDetailContainer = () => {
  const location = useLocation();
  const { state, dispatch } = useContext(Context);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [showDateDialog, setShowDateDialog] = useState(false);
  const [showTimeDialog, setShowTimeDialog] = useState(false);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const pageNo = location.state.pageIndex;
  const handleDateDialog = () => {
    setShowDateDialog(!showDateDialog);
  };

  const handleTimeDialog = () => {
    setShowTimeDialog(!showTimeDialog);
  };

  const handleDateChange = (newDate) => {
    const formattedDate = dayjs(newDate);
    setSelectedDate(formattedDate);
    setShowDateDialog(!showDateDialog);
  };

  const timeSlot = [
    "09:00 AM - 12:00 PM",
    "12:00 PM - 03:00 PM",
    "03:00 PM - 06:00 PM",
    "06:00 PM - 09:00 PM",
  ];

  const [selectedSlot, setSelectedSlot] = useState("09:00 AM - 12:00 PM");
  const [mobileErr, setMobileError] = useState("");

  const handleSlot = (i) => {
    setSelectedSlot(i);
    setShowTimeDialog(!showTimeDialog);
  };

  const handleMobile = (e) => {
    let input = e.target.value;
    if (!/^\d*$/.test(input)) {
      input = input.replace(/[^\d]/g, "");
      setMobileError("Please enter number only");
    } else {
      setMobileError("");
    }
    setMobileNumber(input);
  };

  const handleForm = () => {};
  return (
    <>
      {selectIsMobile(state) ? <MobileHeaderContainer /> : <HeaderContainer />}
      <BlogDetailPage isMobile={selectIsMobile(state)} pageData={pageNo} />
      {selectIsMobile(state) ? <MobileFooterContainer /> : <FooterContainer />}
    </>
  );
};

export default BlogDetailContainer;
