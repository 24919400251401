import React from "react";
import img from "../../assets/labtestImages/lab test bg.jpg";
import img1 from "../../assets/consultationImages/how instant services woek.svg";
import "./LabTestComponent.css";
import bg1 from "../../assets/consultationImages/Rectangle 2586.svg";
import bg2 from "../../assets/labtestImages/labBg.svg";
import mobileImgMain from "../../assets/labtestImages/Frame 427319571.jpg";
import whiteImg1 from "../../assets/physiotherapyImages/mobile/Rectangle 2586.svg";
import dadi from "../../assets/homePageImages/Vector.svg";
const LabTestComponent = (props) => {
  const { isMobile, arr, arr1, handleEnquireNow } = props;
  return (
    <>
      {isMobile ? (
        <div className="homePageContainer1">
          <img src={mobileImgMain} style={{ width: "100%" }} />

          <div className="consultationPageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Lab Tests At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Enjoy the convenience of home-based lab tests, providing accurate
              results for <br />
              proactive health management.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      ) : (
        <div className="homePageContainer1">
          <img src={img} className="consultationPageImg1" />
          <div className="homePageContainer1Content">
            <h1 className="consultationPageContainer1Content-Head">
              Lab Tests At Home
            </h1>
            <p className="consultationPageContainer1Content-text">
              Enjoy the convenience of home-based lab tests, providing accurate
              results for <br />
              proactive health management.
            </p>
            <button
              onClick={handleEnquireNow}
              className="consultationPageContainer1Content-btn"
            >
              Enquire Now
            </button>
          </div>
        </div>
      )}

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">
          How Instant Services Work?
        </h4>
        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      <img
        src={img1}
        style={
          isMobile
            ? { width: "90%", display: "block", margin: " 2.5rem auto" }
            : { width: "70%", display: "block", margin: " 3rem auto" }
        }
      />

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Types of Lab Tests</h4>
        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%", left: "60%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      <div className="labTestPageContainer2">
        {arr.map((item) => {
          return (
            <>
              {isMobile ? (
                <div className="labTestPageContainer2-card">
                  <div style={{ width: "35%" }}>
                    <img
                      src={item.arrImg}
                      className="labTestPageContainer2-cardImg"
                    />
                  </div>
                  <div style={{ width: "65%" }}>
                    <h4 className="labTestPageContainer2-cardHead">
                      {item.head}
                    </h4>
                    <p className="labTestPageContainer2-cardText">
                      {item.text}
                    </p>

                    <div className="labTestPageContainer2-cardDiv">
                      <button className="labTestPageContainer2-cardBtn1">
                        Starting at &#8377;{item.price}
                      </button>
                      <button
                        onClick={() => handleEnquireNow()}
                        className="labTestPageContainer2-cardBtn2"
                      >
                        Enquire Now
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="labTestPageContainer2-card">
                  <h4 className="labTestPageContainer2-cardHead">
                    {item.head}
                  </h4>
                  <p className="labTestPageContainer2-cardText">{item.text}</p>
                  <img
                    src={item.arrImg}
                    className="labTestPageContainer2-cardImg"
                  />
                  <div className="labTestPageContainer2-cardDiv">
                    <button className="labTestPageContainer2-cardBtn1">
                      Starting at &#8377;{item.price}
                    </button>
                    <button
                      onClick={() => handleEnquireNow()}
                      className="labTestPageContainer2-cardBtn2"
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>

      <div
        style={isMobile ? {} : { padding: "32px 0px" }}
        className="homePageContainer4-headMainCont"
      >
        <h4 className="homePageContainer4-headMain">Why Lab Test?</h4>
        {isMobile ? (
          <></>
        ) : (
          <img
            style={{ top: "70%", left: "57%" }}
            className="homePageContainer4-headMain-dadi3"
            src={dadi}
          />
        )}
      </div>

      <div className="consultationPageContainer3">
        <img
          alt="images"
          src={bg2}
          className="elderlyCarePageContainer3-img1"
        />
        {arr1.map((item, index) => {
          return (
            <div key={index} className="careGiverPageContainer3-card">
              <img
                src={item.arrImg}
                className="consultationPageContainer3-Img"
              />
              <div className="careGiverPageContainer3-card-div">
                {isMobile ? (
                  <img
                    alt="images"
                    src={whiteImg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                ) : (
                  <img
                    alt="images"
                    src={bg1}
                    className="careGiverPageContainer3-cardImg1"
                  />
                )}

                <h5 className="careGiverPageContainer3-cardHead">
                  {item.head}
                </h5>
                <p className="careGiverPageContainer3-cardText2">{item.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LabTestComponent;
